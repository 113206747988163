import dayjs from 'dayjs';

const today = dayjs();

// these are the date picker presets that define the start
// and end date for different options.

const presets = [
  {
    text: 'Today',
    start: today,
    end: today,
  },
  {
    text: 'Yesterday',
    start: dayjs().subtract(1, 'day'),
    end: dayjs().subtract(1, 'day'),
  },
  {
    text: 'This Week',
    start: dayjs().startOf('week'),
    end: dayjs().endOf('week'),
  },
  {
    text: 'Last Week',
    // to get the start date of last week, simply subtract 1 week from today's date and get the start date
    // the same applies for the end date too
    start: dayjs().subtract(1, 'week').startOf('week'),
    end: dayjs().subtract(1, 'week').endOf('week'),
  },
  {
    text: 'Past Two Weeks',
    start: dayjs().subtract(2, 'week').startOf('week'),
    end: dayjs().subtract(1, 'week').endOf('week'),
  },
  {
    text: 'This Month',
    start: dayjs().startOf('month'),
    end: dayjs().endOf('month'),
  },
  {
    text: 'Last Month',
    start: dayjs().subtract(1, 'month').startOf('month'),
    end: dayjs().subtract(1, 'month').endOf('month'),
  },
  {
    text: 'This Year',
    start: dayjs().startOf('year'),
    end: dayjs().endOf('year'),
  },
  {
    text: 'Last Year',
    start: dayjs().subtract(1, 'year').startOf('year'),
    end: dayjs().subtract(1, 'year').endOf('year'),
  },
];

export default presets;
