/* eslint-disable import/no-anonymous-default-export */
import { DashboardAction, DashboardActionTypes } from 'actions/data/dashboard';

const INITIAL_STATE = {
  dashboardFilter: {},
  dashboardDatePreset: 0,
};

export default function (
  dashboardState = INITIAL_STATE,
  action: DashboardAction
) {
  switch (action.type) {
    case DashboardActionTypes.SET_DASHBOARD_FILTER:
      return {
        ...dashboardState,
        dashboardFilter: action.data,
      };

    case DashboardActionTypes.CLEAR_DASHBOARD_FILTER:
      return INITIAL_STATE;

    case DashboardActionTypes.SET_DASHBOARD_DATE_PRESET:
      return {
        ...dashboardState,
        dashboardDatePreset: action.data,
      };

    default:
      return dashboardState;
  }
}
