import React from 'react';
import { connect } from 'react-redux';
import { checkPermissions } from 'utils';

interface Props {
  isManager?: any;
  children?: any;
  renderNoAccess?: any;
  userPermissions: any;
}
const HideControl: React.FC<Props> = ({
  isManager,
  children,
  renderNoAccess,
  userPermissions,
}) => {
  if (checkPermissions(userPermissions, isManager)) {
    return renderNoAccess(children);
  }

  return renderNoAccess();
};

HideControl.defaultProps = {
  renderNoAccess: () => null,
};

export default connect((state: any) => ({
  userPermissions: state?.data?.auth?.user,
}))(HideControl);
