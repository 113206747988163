import FooterLogo from 'assets/images/logo-bottom.svg';
import React from 'react';
import { Figure, Stack } from 'react-bootstrap';

const LoginFooterLayout: React.FC = () => {
  return (
    <>
      <div className="col-md-5 mx-auto login-footer">
        <hr />
        <Stack gap={2} className="footer-content-wrapper">
          <div className="mx-auto">
            <Figure>
              <Figure.Image
                width={275}
                height={180}
                alt="Outcode Software Pvt. ltd."
                src={FooterLogo}
              />
            </Figure>
          </div>
          <div
            className="mx-auto copyright-container"
            style={{ textAlign: 'center' }}>
            COPYRIGHT © 2020 OUTCODE. ALL RIGHTS RESERVED.
          </div>
        </Stack>
      </div>
    </>
  );
};

export default LoginFooterLayout;
