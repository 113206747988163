import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TeamMemberForm from 'components/teams/TeamMemberForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Alert, Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const EditTeamMember: React.FC = () => {
  const { id }: any = useParams();
  const queryClient = useQueryClient();

  let teamMemberAPI = new UserResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const teamQuery = useQuery(
    [`team-member${id}-edit`, id],
    () => teamMemberAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateTeamMember = useMutation((data: any) =>
    teamMemberAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    updateTeamMember.mutate(data, {
      onSuccess: (res: any) => {
        TrackifyToast.success('Member has been edited');
        queryClient.invalidateQueries('teamMemberList');
        history.push({
          pathname: routes.teams.list,
          search: '?name=members',
        });
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.error?.details
          ? error?.response?.data?.error?.details[0]?.message
          : error?.response?.data?.error?.message;

        setErrMsg(errorMessage);
      },
    });
  };

  if (teamQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (teamQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.teams.list}>Team</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.teams.list}?name=members`}>
          Team List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Member</Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="pt-3">Edit Member</h2>
      <div className="col-md-8 mt-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <TeamMemberForm teamMemberData={teamQuery?.data?.data} />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Save
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EditTeamMember;
