import * as Sentry from '@sentry/browser';

/**
 * Initialize Sentry.
 */
export function init() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'Trackify Front-end' + process.env.REACT_APP_RELEASE_VERSION,
    tracesSampleRate: 1.0,
  });
}

/**
 * Catch global errors and send the caught error to Sentry.
 *
 * @param {object} error
 * @param {object} errorInfo
 */
export function catchErrorsWithScope(error: any, errorInfo: any) {
  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
