import Resource from 'api/resource';
import http from 'utils/http';
class ReportResource extends Resource {
  constructor() {
    super('reports');
  }

  /**
   * dashboard-info
   */
  dashboardReport(data?: any) {
    return http({
      url: '/reports/dashboard-info',
      method: 'post',
      data: data,
    });
  }

  /**
   * dashboard-info-team-activities
   */
  teamActivitiesReport(data?: any) {
    return http({
      url: '/reports/users-detail',
      method: 'post',
      data: data,
    });
  }

  /**
   * reports summary
   */
  summary(data?: any) {
    return http({
      url: '/reports/summary',
      method: 'post',
      data: data,
    });
  }

  /**
   * reports detailed
   */
  detailed(data: any) {
    return http({
      url: '/reports/detailed',
      method: 'post',
      data: data,
    });
  }

  /**
   * most-tracked-item
   */
  mostTrackedItem(query: any) {
    return http({
      url: '/reports/most-tracked-item',
      method: 'get',
      params: query,
    });
  }

  exportDetailedReport(data: any) {
    return http({
      url: '/reports/export-detailed',
      method: 'post',
      data: data,
    });
  }
}

export { ReportResource as default };
