import React from 'react';

interface GroupOne {
  name?: string;
  description?: string;
  month_year?: string;
  duration: string;
}

interface NoneComponentProps {
  group_one: GroupOne[];
}
const NoneComponent: React.FC<NoneComponentProps> = ({ group_one }) => {
  return (
    <div>
      {group_one?.map((item, idx) => {
        return (
          <div className="card p-2 m-1"
            style={{
              backgroundColor: '#e6f0f6',
              fontSize: '17px',
              fontWeight: '500',
            }}
            key={idx}>
            <div className="row px-5 ">
              <div className="col col-sm-6 col-md-6">
                {item.name || item.month_year || item.description}
              </div>
              <div className="col col-sm-6 col-md-6" style={{textAlign:'right'}} >
                {item.duration}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(NoneComponent);
