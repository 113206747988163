import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

export interface TimeSheetSkeletonLoaderProps {
  rows?: number;
}

const TimeSheetSkeletonLoader: React.FC<TimeSheetSkeletonLoaderProps> = (
  props
) => {
  const { rows = 3 } = props;
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, rowIndex: number) => (
          <React.Fragment key={rowIndex}>
            <Card className="mt-3">
              <Card.Body>
                <div
                  className="d-flex justify-content-between p-2"
                  style={{ background: '#E4EAEE' }}>
                  <Placeholder xs={4} />
                  <Placeholder xs={4} />
                </div>

                <div className="row p-2 border-bottom time-traker-wrap">
                  <div className="col-md-6">
                    <Placeholder xs={4} />
                  </div>
                  <div className="col">
                    <Placeholder xs={4} />
                  </div>
                  <div className="col">
                    <Placeholder xs={4} />
                  </div>
                  <div className="col">
                    <Placeholder xs={4} />
                  </div>
                  <div className="col">
                    <Placeholder xs={4} />
                  </div>
                  <div className="col">
                    <Placeholder xs={4} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </React.Fragment>
        ))}
    </>
  );
};

export default TimeSheetSkeletonLoader;
