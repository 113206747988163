import TimeSheetResource from 'api/timesheet';
import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import TrackifyToast from 'utils/toast';

interface Props {
  id?: any;
  tagListData: any;
  tagData?: any;
  selectedTags?: any;
  setSelectedTags?: any;
}

const TagList: React.FC<Props> = (props) => {
  const { id, tagListData, tagData, setSelectedTags, selectedTags } = props;

  const timeSheetAPI = new TimeSheetResource();
  const queryClient = useQueryClient();
  const divRef = useRef(null);

  const deleteTimeSheetTag = useMutation(
    (timeSheet: any) =>
      timeSheetAPI.removeTimeSheetTag(timeSheet.id, timeSheet.tagId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('timeSheetList');
        TrackifyToast.success('Updated tag successfully');
      },
      onError: (error: any) => {},
    }
  );

  const addTimeSheetTag = useMutation(
    (timeSheet: any) =>
      timeSheetAPI.addTimeSheetTag(timeSheet.id, { tags: [timeSheet.tagId] }),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('timeSheetList');
        TrackifyToast.success('Updated tag successfully');
      },
      onError: (error: any) => {},
    }
  );

  const handleChange = (e: any, tagId: any) => {
    if (id > 0) {
      if (!e.target.checked) {
        return deleteTimeSheetTag.mutate({ id, tagId });
      }
      return addTimeSheetTag.mutate({ id, tagId });
    } else {
      let isSelectedTag: any = selectedTags || [];

      if (e.target.checked) {
        isSelectedTag.push({ tag_id: tagId });
        setSelectedTags(isSelectedTag);
        return;
      }

      isSelectedTag = isSelectedTag.filter((tag: any) => tag.tag_id !== tagId);
      setSelectedTags(isSelectedTag);
      return;
    }
  };

  return (
    <>
      <div
        ref={divRef}
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          width: '250px',
          height: '150px',
          overflow: 'hidden scroll',
          zIndex: '5',
        }}>
        <ul style={{ padding: '15px' }}>
          {tagListData.map((tag: any) => {
            return (
              <div key={`${id}${tag.id}`}>
                <div style={{ display: 'flex' }}>
                  <Form.Check
                    value={tag.id}
                    id={tag.id}
                    defaultChecked={
                      tagData && tagData.length > 0
                        ? tagData.filter((e: any) => e.tag_id === tag.id)
                            .length > 0
                          ? true
                          : false
                        : selectedTags?.filter((e: any) => e.tag_id === tag.id)
                            .length > 0
                        ? true
                        : false
                    }
                    label={tag.name}
                    onChange={(e) => handleChange(e, tag.id)}
                  />
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TagList;
