import GroupResource from 'api/groups';
import UserResource from 'api/user';
import CustomToast from 'components/toast/CustomToast';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import history from 'utils/history';
const animatedComponents = makeAnimated();

const AssignMember: React.FC = () => {
  const { id }: any = useParams();
  const [selectedOption, setSelectedOption] = useState<any>();

  let teamGroupAPI = new GroupResource();
  let teamMemberAPI = new UserResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const [successData, setSuccessData] = useState<any>();
  const methods = useForm<any>();

  const teamQuery = useQuery(
    [`assign-team-group${id}`, id],
    async () => {
      const response = await teamGroupAPI.get(id);
      if (response?.data?.data?.members.length > 0) {
        const members = await response?.data?.data?.members.map(
          (member: any) => {
            return {
              label: member?.user?.name,
              value: member?.user_id,
            };
          }
        );
        if (window.location.pathname.includes('groups'))
          setSelectedOption(members);
      }
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const assignGroupMember = useMutation(
    (data: any) => teamGroupAPI.assignMember(id, data),
    {
      onSuccess: (res: any) => {
        setSuccessData({
          title: 'Assigned Member successfully.',
          status: 'success',
        });
        history.push({
          pathname: routes.teams.list,
          search: '?name=groups',
        });
      },
      onError: (error: any, response: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const membersQuery = useQuery(
    'memebers',
    () =>
      teamMemberAPI
        .list({ status: 'active', limit: 1000 })
        .then((res: any) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const memberList = membersQuery?.data;
  const memberOptions = memberList?.map((member: any) => ({
    label: member.name,
    value: member.id,
  }));

  if (teamQuery.isError) {
    history.push(routes.teams.list);
  }

  const onSubmit = () => {
    if (selectedOption.length >= 0) {
      const members = selectedOption.map((option: any) => {
        return option.value;
      });
      assignGroupMember.mutate({ members });
    }
  };

  return (
    <>
      <h2>Assign Member to Group: {teamQuery?.data?.name}</h2>
      <div className="col-md-8">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
          <Select
            value={selectedOption}
            options={memberOptions}
            onChange={(e) => setSelectedOption(e)}
            components={animatedComponents}
            isMulti
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </div>
      {successData && <CustomToast toastData={successData} />}
    </>
  );
};

export default AssignMember;
