import Resource from 'api/resource';
import http from 'utils/http';

class TimeSheetResource extends Resource {
  constructor() {
    super('time-sheets');
  }

  inProgressTimeSheet() {
    return http({
      url: '/time-sheets/in-progress',
      method: 'get',
    });
  }

  addTimeSheetTag(id: number, data: any) {
    return http({
      url: `/time-sheets/${id}/tags`,
      method: 'post',
      data: data,
    });
  }

  removeTimeSheetTag(id: number, tagId: any) {
    return http({
      url: `/time-sheets/${id}/tags/${tagId}`,
      method: 'delete',
    });
  }

  stopTimeSheet(id: number) {
    return http({
      url: `/time-sheets/stop/${id}`,
      method: 'put',
    });
  }
}

export { TimeSheetResource as default };
