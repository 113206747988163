import TagResource from 'api/tags';
import TagForm from 'components/tags/TagForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const AddTag: React.FC = () => {
  let tagAPI = new TagResource();

  const [errMsg, setErrMsg] = useState<string>('');

  const methods = useForm<any>();

  const addTag = useMutation((data: any) => tagAPI.store(data), {
    onSuccess: (res: any) => {
      TrackifyToast.success('Tag created sucessfully');
      history.push(routes.tags.list);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.message
        : error?.response?.data?.error?.message;

      setErrMsg(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    addTag.mutate(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.tags.list}>Tag</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.tags.list}`}>Tag List</Breadcrumb.Item>
        <Breadcrumb.Item>Add Tag</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Create Tag</h2>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <TagForm />
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={addTag.isLoading}>
              Add
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddTag;
