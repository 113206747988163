import axios from "axios";
import http from "utils/http";

export function login(data: any) {
  return http({
    url: "/auth/login",
    method: "post",
    data: data,
  });
}

export function getAuthUser() {
  return http({
    url: "/auth/user",
    method: "get",
  });
}

export function updateAuthUser(data: any) {
  return http({
    url: "/auth",
    method: "put",
    data: data,
  });
}

export function logout(refreshToken: string) {
  return http({
    url: "/auth/logout",
    method: "post",
    data: { refresh_token: refreshToken },
  });
}

const CancelToken = axios.CancelToken;
let cancel: any;

export function refreshUser(refreshToken: string) {
  if (cancel) {
    cancel(); // cancel request
  }

  return http({
    url: "/auth/token",
    method: "post",
    data: { refresh_token: refreshToken },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

export function forgotPassword(data: any) {
  return http({
    url: "/users/forgot-password",
    method: "post",
    data: data,
  });
}

export function changePassword(data: any) {
  return http({
    url: "/auth/change-password",
    method: "post",
    data: data,
  });
}

export function resetPassword(data: any) {
  return http({
    url: "/users/reset-password",
    method: "post",
    data: data,
  });
}

export function setNewPassword(data: any) {
  return http({
    url: "/users/set-password",
    method: "post",
    data: data,
  });
}

export function verifyEmail(data: any) {
  return http({
    url: "/verify-email",
    method: "post",
    data: data,
  });
}

export function resendVerificationEmail(data: any) {
  return http({
    url: "/resend-verify-email",
    method: "post",
    data: data,
  });
}
