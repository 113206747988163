import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import LoginFooterLayout from 'layouts/LoginLayout/LoginFooterLayout';
import LoginHeaderLayout from 'layouts/LoginLayout/LoginHeaderLayout';
import React, { useState } from 'react';
import { Button, Container, Form, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { validEmail } from 'utils/validate';
interface LoginFormInputs {
  email: string;
  password: string;
}

interface Props {
  login: (email: string, pw: string) => void;
}

const Login: React.FC<Props> = (props) => {
  const { login } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<any>();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [showText, setShowText] = useState('password');

  const onSubmit = async (data: any) => {
    try {
      await login(data.email, data.password);
    } catch (e: any) {
      setIsError(true);
      setError(e.message);
    }
  };

  const [showPass, SetShowPass] = useState(true);

  const showHidePass = () => {
    SetShowPass(!showPass);
    setShowText(showPass ? 'text' : 'password');
  };

  return (
    <>
      <LoginHeaderLayout />
      <Container className="login">
        <Stack
          gap={3}
          className="col-md-12 mx-auto col-12 col-sm-12 col-lg-12 login-wrapper">
          <div className="p-4 login--inner">
            <h4>Log In</h4>
            {isError && <p className="alert-danger p-2">{error}</p>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  className={errors.email ? 'is-invalid' : ''}
                  placeholder="Enter email"
                  {...register('email', {
                    required: 'Email address is required',
                    validate: (value) =>
                      validEmail(value) || 'Please provide valid Email',
                  })}
                />
                {errors.email && errors.email?.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <div className="password-container">
                  <Form.Control
                    type={showText}
                    className={errors.password ? 'is-invalid' : ''}
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message:
                          'Password must be of minimum 8 characters with an UPPERCASE LETTER and a SYMBOL',
                      },
                    })}
                  />
                  {errors.password && errors.password?.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password && errors.password?.message}
                    </Form.Control.Feedback>
                  )}
                  <i aria-hidden="true" onClick={showHidePass}>
                    {' '}
                    {showPass ? <GoEye /> : <GoEyeClosed />}{' '}
                  </i>
                </div>
              </Form.Group>

              <Stack direction="horizontal" gap={2}>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Stay logged in" />
                  </Form.Group>
                </div>

                <div className="ms-auto mb-3">
                  <Link to={routes.auth.forgotPassword}>Forgot password ?</Link>
                </div>
              </Stack>
              <Stack gap={2}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={formState.isSubmitting}>
                  LOG IN
                </Button>
              </Stack>
            </form>
          </div>
          <div className="mx-auto privacy-btn_container">
            <Stack direction="horizontal" gap={2}>
              <a href="#">PRIVACY POLICY</a>
              <div className="vr" />
              <a href="#">USER NOTICE</a>
            </Stack>
          </div>
        </Stack>
      </Container>
      <LoginFooterLayout />
    </>
  );
};

export default withAuthState(Login);
