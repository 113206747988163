import React from 'react';
import { Placeholder } from 'react-bootstrap';

export interface TableSkeletonLoaderProps {
  rows?: number;
  cols?: number;
}

const TableSkeletonLoader: React.FC<TableSkeletonLoaderProps> = (props) => {
  const { rows = 3, cols = 6 } = props;
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, rowIndex: number) => (
          <tr key={rowIndex}>
            {Array(cols)
              .fill(0)
              .map((_, colIndex) => (
                <td key={colIndex}>
                  <Placeholder xs={4} />
                </td>
              ))}
          </tr>
        ))}
    </>
  );
};

export default TableSkeletonLoader;
