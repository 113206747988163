import { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger.svg';
import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import React from 'react';
import { Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import history from 'utils/history';

interface Props {
  handleSideBarToggle: Function;
  toggle: Boolean;
  logout: () => void;
}

const NavBar: React.FC<Props> = (props) => {
  const { logout, handleSideBarToggle, toggle } = props;

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  const bgColor = {
    backgroundColor: '#006ba8',
    color: '#fff',
  };

  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  return (
    <>
      <Navbar expand="lg" style={bgColor} variant="light">
        <Container fluid>
          <div className="burgermenu">
            <HamburgerIcon onClick={() => handleSideBarToggle()} />
          </div>
          <Nav className="d-flex" style={{ maxHeight: '100px' }}>
            <Dropdown autoClose="outside" className="dropstart">
              <Dropdown.Toggle
                id="dropdown-custom-components"
                className="relative">
                {userDetail.image_url !== null}
                {userDetail.image_url !== null && (
                  <Image
                    src={userDetail?.image_url}
                    roundedCircle
                    className="rounded-image absolute"
                  />
                )}
                {userDetail.image_url === null && (
                  <BiUser
                    className="rounded-image absolute"
                    style={{ color: '#000' }}
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ top: '100%', marginTop: '0.125rem' }}>
                <Dropdown.Item
                  onClick={() => history.push(routes.profile.myProfile)}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={onLogoutClick}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default withAuthState(NavBar);
