// Auth
export const ACCESS_TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires';

// Pagination

export const DEFAULT_PAGE_SIZE = 15;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];

export const CSVFILETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const CSVFILEEXTENSION = '.xlsx';

export const ADMIN_ROLES = [1, 2];
export const SUPER_ADMIN_ROLES = [1];

export enum ProjectTypeEnum {
  'scope' = 'Scope',
  'dedicated' = 'Dedicated',
  'peak' = 'Peak',
  'outcode_talent' = 'Outcode Talent',
}
