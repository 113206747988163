import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface Props {
  toastData?: any;
}

const CustomToast: React.FC<Props> = (props) => {
  const { toastData } = props;
  const [show, setShow] = useState(true);

  return (
    <>
      {toastData && (
        <ToastContainer position="bottom-center">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={5000}
            autohide
          >
            <Toast.Body className={`bg-${toastData?.status}`}>
              {toastData?.title}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};

export default CustomToast;
