import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  clientData?: any;
}

const ClientForm: React.FC<Props> = (props) => {
  const { clientData } = props;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<any>();

  return (
    <>
      <form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="formPlaintextclientName">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              className={errors?.name ? 'is-invalid' : ''}
              defaultValue={clientData?.name}
              placeholder="Client Name"
              maxLength={24}
              {...register('name', {
                required: 'Client Name must be between 3 to 24 characters',
                minLength: {
                  value: 3,
                  message: 'Must be atleast 3 characters',
                },
              })}
            />

            <Form.Control.Feedback type="invalid">
              {errors?.name && errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextClient">
          <Form.Label column sm="2">
            Status
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="status"
                  {...field}
                  defaultValue={clientData?.status}>
                  <option>Select</option>
                  <option value="active">Active</option>
                  <option value="archived">Archive</option>
                </select>
              )}
            />
          </Col>
        </Form.Group>
      </form>
    </>
  );
};

export default ClientForm;
