import UserResource from 'api/user';
import TimeTrackerForm from 'components/tracker/TimeTrackerForm';
import TimeTrackerLog from 'components/tracker/TimeTrackerLog';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import Select from 'react-select';
import HideControl from 'services/HideControl';
import { IUser } from 'utils/interfaces/userInterface';

const TrackerList: React.FC = () => {
  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const [isAdded, setIsAdded] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedRole, setSelectedRole] = useState('me');

  const selectOption = (event: any) => {
    setSelectedUser(event.id);
  };

  const addLog = (data: boolean) => {
    setIsAdded(data);
  };
  const userAPI = new UserResource();
  const usersQuery = useQuery<any, unknown, IUser[]>(
    ['all-users'],
    async () => {
      const response = await userAPI.list({ limit: 1000 });
      return response?.data?.data;
    }
  );
  const { data: users } = usersQuery;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.tracker.list}>Tracker</Breadcrumb.Item>
        <Breadcrumb.Item>Tracker List</Breadcrumb.Item>
      </Breadcrumb>
      {/* This HideControl gives user the page Tracker and gives Superadmin and Admin to choose their tracker page as well as user's tracker list  */}
      <HideControl
        renderNoAccess={(data: any) => {
          return data ? data : <h2>Tracker</h2>;
        }}>
        <div className="d-flex justify-content-between">
          <div style={{ fontSize: '30px', fontWeight: '500' }}>Tracker</div>
          <div style={{ width: '100px' }}>
            <Form.Select
              defaultValue={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.target.value);
              }}>
              <option value="me">Me</option>
              <option value="team">Team</option>
            </Form.Select>
          </div>
        </div>
      </HideControl>

      <div className="mt-5">
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : <TimeTrackerForm addLog={addLog} />;
          }}>
          {selectedRole === 'team' ? (
            <Select
              placeholder="Select User"
              id="project_id"
              options={users}
              defaultValue={users?.filter((u) => u.id === selectedUser)}
              onChange={(e: any) => selectOption(e)}
              isSearchable={true}
              escapeClearsValue
              getOptionLabel={(options: any) => options['name']}
              getOptionValue={(options: any) => options['id']}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '25px',
                  borderColor: '#C6D2D9',
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  margin: '-5px',
                }),
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          ) : (
            <TimeTrackerForm addLog={addLog} />
          )}
        </HideControl>
      </div>

      <div className="mt-5">
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? (
              data
            ) : (
              <TimeTrackerLog isAdded={isAdded} setIsAdded={setIsAdded} />
            );
          }}>
          {selectedRole === 'team' && (
            <>
              {!selectedUser ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    paddingTop: '100px',
                  }}>
                  Please select user to edit time sheets.
                </div>
              ) : (
                <TimeTrackerLog
                  isAdded={isAdded}
                  setIsAdded={setIsAdded}
                  userId={selectedUser}
                  selectedRole={selectedRole}
                />
              )}
            </>
          )}
        </HideControl>
      </div>
    </>
  );
};

export default TrackerList;
