import UserResource from 'api/user';
import { Pagination } from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TeamMemberListItem from 'components/teams/TeamMemberListItem';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import React, { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  status: string;
  keyword: string;
}

const TeamMemberList: React.FC = () => {
  let teamAPI = new UserResource();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    status: 'active',
    keyword: '',
  });
  const methods = useForm<any>();

  const queryList = useQuery(
    [
      'teamMemberList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await teamAPI.list(queryParams);

      return response?.data;
    }
  );

  const { data: teamList, isLoading: isTeamListLoading } = queryList;

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
      status: data.status,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      status: '',
      keyword: '',
    }));
  };

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
    if (data.key === 'Enter') {
      filterList(data);
    }
  };

  const debouncedSearch = useDebounce(onSubmit);

  return (
    <>
      <div>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <div className="d-flex align-items-end flex-column">
            <Button
              style={{ float: 'right' }}
              className="button primary-button"
              onClick={() => history.push(routes.teams.members.create)}>
              Add
            </Button>
          </div>
        </HideControl>
        <div className="mt-3">
          <form onChange={methods.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 col-xl-4 mt-2 mb-2">
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  defaultValue={'active'}
                  {...methods.register('status')}>
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="invited">Invited</option>
                </select>
              </div>
              <div className="col col-xl-8 search-and-btns mt-2 mb-2">
                <Form.Control
                  type="text"
                  placeholder="Search by name or email"
                  min={0}
                  max={24}
                  autoComplete="off"
                  {...methods.register('keyword')}
                  onChange={(e) =>
                    debouncedSearch({
                      ...filterParams,
                      keyword: e.target.value,
                    })
                  }
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                />
                <div className="col mx-auto">
                  <Button
                    type="submit"
                    className="button danger-button"
                    onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-3 team-member-table-wrapper table-responsive">
          <Table>
            <thead>
              <tr>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ROLE</th>
                <th>GROUP</th>
                <HideControl
                  renderNoAccess={(data: any) => {
                    return data ? data : '';
                  }}>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </HideControl>
              </tr>
            </thead>
            <tbody>
              {!isTeamListLoading &&
                teamList?.data?.map((teamListData: any, index: number) => (
                  <TeamMemberListItem
                    key={teamListData.id}
                    teamData={teamListData}
                  />
                ))}

              {isTeamListLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
              )}
            </tbody>
          </Table>
          <Pagination
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            dataList={queryList}
          />
        </div>
      </div>
    </>
  );
};

export default TeamMemberList;
