import { Chart, registerables } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getTime } from 'utils';
Chart.register(...registerables);

interface Props {
  labelData: any;
  title?: string;
  isLegendDisplay?: boolean;
  reportData: any;
}

const GroupBarComponent: React.FunctionComponent<Props> = (props) => {
  const { labelData, title, isLegendDisplay = false, reportData } = props;
  const labels = labelData;
  const data = {
    labels: labels ? labels : [],
    datasets: reportData ? reportData : [],
  };

  const options = {
    plugins: {
      legend: {
        display: isLegendDisplay,
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: (value: any) => {
            return `${value.dataset.label}: ${getTime(value.raw)}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    barThickness: 'flex',
    maxBarThickness: 100,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: (value: any) => getTime(value),
        },
      },
    },
  };

  return (
    <>
      <div style={{ width: '100%', height: '500px' }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};
export default GroupBarComponent;
