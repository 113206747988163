export enum ReportActionTypes {
  SET_REPORTS_FILTER = 'SET_REPORTS_FILTER',
  SET_REPORTS_DATE_PRESET = 'SET_REPORTS_DATE_PRESET',
}

export interface ReportAction {
  data: any;
  type: ReportActionTypes;
}

export function setReportsFilter(filters: any): ReportAction {
  return {
    data: filters,
    type: ReportActionTypes.SET_REPORTS_FILTER,
  };
}

export function setReportsDatePreset(data: number): ReportAction {
  return {
    data,
    type: ReportActionTypes.SET_REPORTS_DATE_PRESET,
  };
}
