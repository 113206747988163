import React from 'react';
import { Spinner } from 'react-bootstrap';

export const CenterSpinner: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ height: 'calc(100vh - 100px)' }}>
      <Spinner animation="grow" variant="info" />
    </div>
  );
};
