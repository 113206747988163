import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import dashboard from './dashboard';
import reports from './reports';

/**
 * Persist Auth Reducer.
 */
const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const reportPersistConfig = {
  key: 'reports',
  storage: storage,
};

const dashboardPersistConfig = {
  key: 'dashboard',
  storage: storage,
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  reports: persistReducer(reportPersistConfig, reports),
  dashboard: persistReducer(dashboardPersistConfig, dashboard),
});
