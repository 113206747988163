import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
  selected: Date;
  onChange: (date: Date) => void;
  disabled: any;
  onKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
  maxDate?: Date | null | undefined;
}

const Datepicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  disabled,
  onKeyDown,
  maxDate,
}) => {
  return (
    <DatePicker
      selected={selected}
      onChange={(date) => onChange(date as Date)}
      disabled={disabled}
      onKeyDown={onKeyDown}
      maxDate={maxDate}
    />
  );
};

export default Datepicker;
