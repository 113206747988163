import TagGroup from 'api/tags';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TagForm from 'components/tags/TagForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Alert, Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const EditTeamGroup: React.FC = () => {
  const { id }: any = useParams();
  const queryClient = useQueryClient();

  let tagGroupAPI = new TagGroup();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const tagQuery = useQuery(
    [`tag-${id}-edit`, id],
    () => tagGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateTag = useMutation((data: any) => tagGroupAPI.update(id, data));

  const onSubmit = (data: any) => {
    if (data.client_id === '') data.client_id = null;
    updateTag.mutate(data, {
      onSuccess: (res: any) => {
        TrackifyToast.success('Tag has been edited');
        queryClient.invalidateQueries('tagList');
        history.push(routes.tags.list);
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.error?.details
          ? error?.response?.data?.error?.details[0]?.message
          : error?.response?.data?.error?.message;

        setErrMsg(errorMessage);
      },
    });
  };

  if (tagQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (tagQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.tags.list}>Tag</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.tags.list}`}>Tag List</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Tag</Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="pt-3">Edit Tag</h2>
      <div className="col-md-6 col-sm-10 pt-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <TagForm tagData={tagQuery?.data?.data} />
            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Save
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EditTeamGroup;
