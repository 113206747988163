import { resetPassword } from 'api/auth';
import routes from 'constants/routes';
import { useFormik } from 'formik';
import LoginFooterLayout from 'layouts/LoginLayout/LoginFooterLayout';
import LoginHeaderLayout from 'layouts/LoginLayout/LoginHeaderLayout';
import React, { useState } from 'react';
import { Button, Container, Form, Stack } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';
import { ResetPasswordValidation } from './AuthValidation';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [errMsg, setErrMsg] = useState('');
  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';
  const email = params.get('email') || '';
  if (token === '' || email === '') history.push(routes.auth.login);

  const resetPasswordMutation = useMutation((data) => resetPassword(data), {
    onSuccess: (res: any) => {
      setErrMsg('');
      formik.resetForm();
      TrackifyToast.success('We have emailed your password reset link!');
      history.push(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.error?.details)
        setErrMsg(error?.response?.data?.error?.details[0].message);
      else setErrMsg(error?.response?.data?.error?.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      password: '',
      confirm_password: '',
    },
    validate: ResetPasswordValidation,
    onSubmit: (data: any) => {
      data.token = token;
      if (data.email === email) resetPasswordMutation.mutate(data);
    },
  });

  return (
    <>
      <Container className="login">
        <Stack gap={4} className="col-md-6 mx-auto col-12 col-sm-12 col-lg-4">
          <LoginHeaderLayout />
          <div className="p-4 login--inner">
            <h2>Reset Password?</h2>

            <Form onSubmit={formik.handleSubmit} className="mt-3">
              {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.email && formik.errors.email
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.password && formik.errors.password
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  value={formik.values.confirm_password}
                />
                {formik.touched.confirm_password &&
                formik.errors.confirm_password ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.confirm_password}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>

              <Stack gap={2}>
                <Button variant="primary" type="submit">
                  Request Password Reset
                </Button>
                <Link className="mx-auto mt-3" to={routes.auth.login}>
                  Back to Login
                </Link>
              </Stack>
            </Form>
          </div>
        </Stack>
        <LoginFooterLayout />
      </Container>
    </>
  );
};

export default ResetPassword;
