import ClientResource from 'api/clients';
import ClientItem from 'components/clients/ClientItem';
import { Pagination } from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import React, { useState } from 'react';
import { Breadcrumb, Button, Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  status: string;
  keyword: string;
}

const ClientList: React.FC = () => {
  let clientAPI = new ClientResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    status: 'active',
    keyword: '',
  });

  const methods = useForm<any>();

  const queryList = useQuery(
    [
      'listClient',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await clientAPI.list(queryParams);

      return response?.data;
    }
  );

  const { data: listClient, isLoading: isClientListLoading } = queryList;

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
      status: data.status,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      status: '',
      keyword: '',
    }));
  };

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  // Debounce helps in delaying, onchange Search rather than hitting api each time character is inserted.
  const debouncedSearch = useDebounce(onSubmit);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.clients.list}>Client</Breadcrumb.Item>
        <Breadcrumb.Item>Client List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h2>Clients</h2>
        <Button
          style={{ float: 'right' }}
          className="button primary-button"
          onClick={() => history.push(routes.clients.create)}>
          Add
        </Button>
      </div>
      <>
        <div className="mt-1">
          <form onChange={methods.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 col-xl-4 mt-2 mb-2">
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  defaultValue={'active'}
                  {...methods.register('status')}>
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
              <div className="col col-xl-8 mt-2 mb-2 search-and-btns">
                <Form.Control
                  type="text"
                  placeholder="Search by client name"
                  min={0}
                  max={24}
                  autoComplete="off"
                  {...methods.register('keyword')}
                  onChange={(e) =>
                    debouncedSearch({
                      ...filterParams,
                      keyword: e.target.value,
                    })
                  }
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                />
                <div className="col mx-auto">
                  <Button
                    type="submit"
                    className="button danger-button"
                    onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
      <Table className="mt-3">
        <thead>
          <tr>
            <th>clients</th>
            <HideControl
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </HideControl>
          </tr>
        </thead>
        <tbody>
          {!isClientListLoading &&
            listClient?.data?.map((clientList: any, index: number) => (
              <ClientItem key={clientList.id} clientData={clientList} />
            ))}

          {isClientListLoading && (
            <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
          )}
        </tbody>
      </Table>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </>
  );
};

export default ClientList;
