import { LottieNotFound } from 'assets/lotties';
import React from 'react';
import { Button } from 'react-bootstrap';
import Lottie from 'react-lottie';

export interface FallBackUIProps {}

const FallBackUI: React.FC<FallBackUIProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ width: '100%', height: '100vh' }}>
      <Lottie options={defaultOptions} height={400} width={400} />
      <h3>Sorry, something went wrong.</h3>
      <p>
        Our team has been notified and we will get it fixed as soon as we can.
      </p>
      <div className="mt-10 d-grid gap-2 d-md-flex">
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            window.location.reload();
          }}>
          Reload page
        </Button>
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            window.location.href = '/dashboard';
          }}>
          Take me home
        </Button>
      </div>
    </div>
  );
};

export default FallBackUI;
