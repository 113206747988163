import App from 'components/App';
import init from 'init';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'utils/sentry';
import './colors.scss';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';

init();
Sentry.init();
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
          <div id={'spinner-container'}>
              <div id={'spinner'}></div>
          </div>

      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
