import { INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { Pagination as Paginations } from 'react-bootstrap';

interface Props {
  dataList: any;
  filterParams: any;
  setFilterParams: Function;
}

export const Pagination: React.FC<Props> = (props) => {
  let { dataList, setFilterParams, filterParams } = props;

  const [currentPage, setCurrentPage] = useState<number>(INITIAL_CURRENT_PAGE);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [perPageItem, setPerPageItem] = useState<number>(0);
  const lastPage = dataList?.data?.meta?.last_page;
  let { isLoading } = dataList;

  let metaData = dataList?.data?.meta;

  useEffect(() => {
    if (metaData) {
      if (!metaData.total || metaData.total === 1) return;
      setCurrentPage(filterParams?.currentPage);
      setTotalItems(metaData.total);
      setPerPageItem(metaData.per_page);
    }
  }, [metaData, filterParams?.currentPage]);

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: nextPage,
    }));
    setCurrentPage(nextPage);
  };

  let isPageNumberOutOfRange: any;

  const pageGroup = [...new Array(lastPage)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === lastPage;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Paginations.Item
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          active={pageNumber === currentPage}>
          {pageNumber}
        </Paginations.Item>
      );
    }
    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Paginations.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    <>
      {!isLoading && metaData?.total > metaData?.per_page && (
        <Paginations className="justify-content-center">
          <Paginations.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Paginations.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {/* {pageGroup()} */}
          {pageGroup}

          <Paginations.Next
            disabled={currentPage === lastPage}
            onClick={() => handlePageChange(currentPage + 1)}
          />
          <Paginations.Last
            onClick={() => handlePageChange(lastPage)}
            disabled={currentPage === lastPage}
          />
        </Paginations>
      )}
    </>
  );
};
