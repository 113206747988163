import { Chart, registerables } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

Chart.register(...registerables);

interface Props {
  title?: string;
  isLegendDisplay?: boolean;
  reportData: any;
  reportType?: string;
}

const DoughnutComponent: React.FunctionComponent<Props> = (props) => {
  const { title, isLegendDisplay = false, reportData, reportType } = props;

  const data = {
    labels: reportData.map((res: any) => res?.labels),
    datasets: [
      {
        data: reportData.map((res: any) =>
          reportType === 'PROJECT' ? res?.percentage : res?.duration
        ),
        backgroundColor: reportData.map((res: any) => res?.color),
        borderColor: reportData.map((res: any) => res?.color),
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: isLegendDisplay,
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: (value: any) => {
            return `${value.label}: ${value.raw}%`;
          },
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={data} options={options} />
    </>
  );
};
export default DoughnutComponent;
