/* eslint-disable import/no-anonymous-default-export */
import { ReportAction, ReportActionTypes } from 'actions/data/reports';

const INITIAL_STATE = {
  reportsFilter: {},
  reportDatePreset: 0,
};

export default function (reportState = INITIAL_STATE, action: ReportAction) {
  switch (action.type) {
    case ReportActionTypes.SET_REPORTS_FILTER:
      return {
        ...reportState,
        reportsFilter: action.data,
      };

    case ReportActionTypes.SET_REPORTS_DATE_PRESET:
      return {
        ...reportState,
        reportDatePreset: action.data,
      };

    default:
      return reportState;
  }
}
