import { ReactComponent as DashboardIcon } from 'assets/icons/dashboardIcon.svg';
import { ReactComponent as ProjectIcon } from 'assets/icons/projectIcon.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/reportIcon.svg';
import { ReactComponent as SandClockIcon } from 'assets/icons/sandclockIcon.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tagIcon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/usersIcon.svg';
import HeaderLogo from 'assets/images/Logo.svg';
import MiniHeaderLogo from 'assets/images/miniLogo.svg';
import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import React from 'react';
import { Col, Image, Nav, Row, Stack } from 'react-bootstrap';
import { GoOrganization } from 'react-icons/go';
import { Link, useLocation } from 'react-router-dom';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface Props {
  toggle: any;
}

const SideBar: React.FC<Props> = (props) => {
  let { toggle } = props;
  const location = useLocation();
  const path = location.pathname.split('/');

  return (
    <>
      <Stack gap={3} className="trackify-sidebar sidebar">
        <div className="">
          <div className="image">
            <Image
              src={toggle ? MiniHeaderLogo : HeaderLogo}
              onClick={() => history.push(routes.dashboard)}
            />
          </div>
          <Nav
            defaultActiveKey={path[1]}
            className="flex-column mt-5"
            variant="pills">
            <Nav.Link as={Link} to={routes.tracker.list} eventKey="tracker">
              <Row className="Nav-item-wrap" title="TRACKER">
                <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                  TRACKER
                </Col>
                <Col md="2" sm="1" className="px-2">
                  <SandClockIcon />
                </Col>
              </Row>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={routes.dashboard}
              eventKey="dashboard"
              className={
                routes.dashboard && location.pathname === '/' ? 'active' : ''
              }>
              <Row title="DASHBOARD">
                <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                  DASHBOARD
                </Col>
                <Col md="2" sm="1" className="px-2">
                  <DashboardIcon />
                </Col>
              </Row>
            </Nav.Link>
            <Nav.Link as={Link} to={routes.reports} eventKey="reports">
              <Row title="REPORTS">
                <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                  REPORTS
                </Col>
                <Col md="2" sm="1" className="px-2">
                  <ReportIcon />
                </Col>
              </Row>
            </Nav.Link>
            <Nav.Link as={Link} to={routes.projects.list} eventKey="projects">
              <Row title="PROJECTS">
                <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                  PROJECTS
                </Col>
                <Col md="2" sm="1" className="px-2">
                  <ProjectIcon />
                </Col>
              </Row>
            </Nav.Link>
            <Nav.Link as={Link} to={routes.teams.list} eventKey="teams">
              <Row title="TEAMS">
                <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                  TEAMS
                </Col>
                <Col md="2" sm="1" className="px-2">
                  <UserIcon />
                </Col>
              </Row>
            </Nav.Link>
            <HideControl
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Nav.Link as={Link} to={routes.tags.list} eventKey="tags">
                <Row title="TAGS">
                  <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                    TAGS
                  </Col>
                  <Col md="2" sm="1" className="px-2">
                    <TagIcon />
                  </Col>
                </Row>
              </Nav.Link>
            </HideControl>
            <HideControl
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <Nav.Link as={Link} to={routes.clients.list} eventKey="client">
                <Row title="CLIENT">
                  <Col className={toggle ? 'toggle-text hide-mobile' : ''}>
                    Clients
                  </Col>
                  <Col md="2" sm="1" className="px-2">
                    <GoOrganization size="20px" />
                  </Col>
                </Row>
              </Nav.Link>
            </HideControl>
          </Nav>
        </div>
      </Stack>
    </>
  );
};

export default withAuthState(SideBar);
