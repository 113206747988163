import ProjectResource from 'api/projects';
import { Pagination } from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ProjectListItem from 'components/projects/ProjectListItem';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import React, { useState } from 'react';
import { Breadcrumb, Button, Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import HideControl from 'services/HideControl';
import history from 'utils/history';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  status: string;
  keyword: string;
}

const ProjectList: React.FC = () => {
  let projectAPI = new ProjectResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    status: 'active',
    keyword: '',
  });

  const methods = useForm<any>();

  const queryList = useQuery(
    [
      'listProject',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await projectAPI.list(queryParams);

      return response?.data;
    }
  );
  const { data: listProject, isLoading: isProjectListLoading } = queryList;

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
      status: data.status,
    }));
  };

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    methods.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      status: '',
      keyword: '',
    }));
  };

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  // Debounce helps in delaying, onchange Search rather than hitting api each time character is inserted.
  const debouncedSearch = useDebounce(onSubmit);
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.projects.list}>Project</Breadcrumb.Item>
        <Breadcrumb.Item>Project List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h2>Projects</h2>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <div className="d-flex align-items-end flex-column">
            <Button
              style={{ float: 'right' }}
              className="button primary-button"
              onClick={() => history.push(routes.projects.create)}>
              Add
            </Button>
          </div>
        </HideControl>
      </div>
      <>
        <div className="mt-3">
          <form onChange={methods.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 col-xl-4 mt-2 mb-2">
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  defaultValue={'active'}
                  {...methods.register('status')}>
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div className="col col-xl-8 search-and-btns mt-2 mb-2">
                <Form.Control
                  type="text"
                  placeholder="Search by Project name"
                  min={0}
                  max={24}
                  autoComplete="off"
                  {...methods.register('keyword')}
                  onChange={(e) =>
                    debouncedSearch({
                      ...filterParams,
                      keyword: e.target.value,
                    })
                  }
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                />
                <div className="col mx-auto">
                  <Button
                    type="reset"
                    className="button danger-button"
                    onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
      <div className="projectlist-wrapper">
        <Table hover className="mt-3">
          <thead>
            <tr>
              <th>PROJECTS</th>
              <th>CLIENT</th>
              <th>PRIVACY</th>
              <th>ClickUp Space ID</th>
              <th>Type</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {!isProjectListLoading &&
              listProject?.data?.map((projectList: any, index: number) => (
                <ProjectListItem
                  key={projectList.id}
                  projectData={projectList}
                />
              ))}

            {isProjectListLoading && (
              <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </>
  );
};

export default ProjectList;
