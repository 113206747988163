import ProjectResource from 'api/projects';
import React, { useState } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import TrackifyToast from '../../utils/toast';

interface Props {
  membersData: any;
  isManager: any;
  projectId: number;
}

interface ITechStacks {
  id: number;
  tech_stack_id: number;
  project_member_id: number;
  tech_stack: ITechStack;
}
interface ITechStack {
  id: number;
  name: string;
}

const ProjectMemberListItem: React.FC<Props> = (props) => {
  const { membersData, isManager, projectId } = props;

  const projectAPI = new ProjectResource();
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setDeleteErrorMsg('');
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleDeleteClose = () => {
    setDeleteErrorMsg('');
    setShowDelete(false);
  };

  const handleDeleteShow = () => {
    setShowDelete(true);
  };

  const revokeManagerAccess = useMutation(
    (data: any) =>
      projectAPI.revokeProjectManager(projectId, membersData?.user_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`project-${projectId}`);
        handleClose();
        TrackifyToast.success('Manager access successfully revoked.');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const grantManagerAccess = useMutation(
    (data: any) =>
      projectAPI.grantProjectManager(projectId, membersData?.user_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`project-${projectId}`);
        handleClose();
        TrackifyToast.success('Manager access successfully granted.');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onConfirm = () => {
    membersData.is_manager === true
      ? revokeManagerAccess.mutate({})
      : grantManagerAccess.mutate({});
  };

  const deleteMember = useMutation(
    (data: any) =>
      projectAPI.unassignProjectMember(projectId, membersData?.user_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`project-${projectId}`);
        handleClose();
        TrackifyToast.success('Member Unassign successful.');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onDelete = () => {
    membersData?.project_id === projectId && deleteMember.mutate({});
  };

  const techStacks = membersData?.member_tech_stack.map(
    (techstack: ITechStacks) => techstack?.tech_stack
  );

  const techStack = techStacks?.map((tech: ITechStack) => {
    return tech.name;
  });

  const getMemberDetail = () => {
    return (
      <>
        <tr key={membersData.id}>
          <td
            style={
              membersData.user?.status === 'inactive'
                ? { textDecoration: 'line-through' }
                : { textDecoration: 'none' }
            }>
            {membersData.user?.name}
          </td>
          <td>{membersData.user?.email}</td>
          <td>
            {techStack.length > 0 ? (
              <Badge bg="info ">{techStack.join(' , ')}</Badge>
            ) : (
              '-'
            )}
          </td>
          <td>
            {membersData?.assignment_type?.name
              ? membersData?.assignment_type?.name
              : '-'}
          </td>
          <td>{membersData.user?.phone ? membersData.user?.phone : '-'}</td>
          <HideControl
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <td>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  onChange={handleShow}
                  checked={membersData?.is_manager ? true : false}
                />
              </Form>
            </td>
            <td>
              <MdOutlineDeleteOutline
                style={{ fontSize: '28px', color: 'red', marginLeft: '15px' }}
                onClick={handleDeleteShow}
              />
            </td>
          </HideControl>
        </tr>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              {membersData?.is_manager ? 'Revoke' : 'Grant'} Manager Access
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteErrorMsg && (
              <p className="alert-danger p-2">{deleteErrorMsg}</p>
            )}
            Are you sure you want to{' '}
            {membersData?.is_manager ? 'revoke' : 'grant'} manager access{' '}
            {membersData?.is_manager ? 'from' : 'to'} {membersData?.user.name}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleDeleteClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Assigned Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteErrorMsg && (
              <p className="alert-danger p-2">{deleteErrorMsg}</p>
            )}
            Are you sure you want to unassign the member
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onDelete}>
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return getMemberDetail();
};

export default ProjectMemberListItem;
