import { validEmail } from 'utils/validate';

export const LoginValidation = (values: any) => {
  const errors = {} as any;

  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!validEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }
  return errors;
};

export const ForgotPasswordValidation = (values: any) => {
  const errors = {} as any;

  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!validEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export const ResetPasswordValidation = (values: any) => {
  const errors = {} as any;

  if (!values.email) {
    errors.email = 'Please enter your email';
  } else if (!validEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Please enter password';
  }
  if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Password does not match.';
  }
  return errors;
};

export const SetPasswordValidation = (values: any) => {
  const errors = {} as any;

  const passwordRegex = new RegExp(
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,32}$/
  );

  if (!passwordRegex.test(values.password)) {
    errors.password =
      'Password must be of minimum 8 characters with an UPPERCASE LETTER and a SYMBOL';
  }

  if (!values.password) {
    errors.password = 'Please enter password';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Please enter password';
  }
  if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Password does not match.';
  }
  return errors;
};
