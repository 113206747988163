import HeaderLogo from "assets/images/Logo.svg";
import React from "react";
import { Figure } from "react-bootstrap";

const LoginHeaderLayout: React.FC = () => {
  return (
    <>
      <div className="col-md-5 mx-auto pt-5 text-center">
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="Trackify"
            src={HeaderLogo}
          />
        </Figure>
      </div>
    </>
  );
};

export default LoginHeaderLayout;
