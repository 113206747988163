export enum DashboardActionTypes {
  SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER',
  CLEAR_DASHBOARD_FILTER = 'CLEAR_DASHBOARD_FILTER',
  SET_DASHBOARD_DATE_PRESET = 'SET_DASHBOARD_DATE_PRESET',
}

export interface DashboardAction {
  data: any;
  type: DashboardActionTypes;
}

export function setDashboardFilter(filters: any): DashboardAction {
  return {
    data: filters,
    type: DashboardActionTypes.SET_DASHBOARD_FILTER,
  };
}

export function setDashboardDatePreset(data: number) {
  return {
    data: data,
    type: DashboardActionTypes.SET_DASHBOARD_DATE_PRESET,
  };
}
