import ClientGroup from 'api/clients';
import ClientForm from 'components/clients/ClientForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Alert, Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const EditTeamGroup: React.FC = () => {
  const { id }: any = useParams();
  const queryClient = useQueryClient();

  let clientGroupAPI = new ClientGroup();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const clientQuery = useQuery(
    [`client-${id}-edit`, id],
    () => clientGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateClient = useMutation((data: any) =>
    clientGroupAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    if (data.client_id === '') data.client_id = null;
    updateClient.mutate(data, {
      onSuccess: (res: any) => {
        TrackifyToast.success('Client has been edited');
        queryClient.invalidateQueries('clientList');
        history.push(routes.clients.list);
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.error?.details
          ? error?.response?.data?.error?.details[0]?.message
          : error?.response?.data?.error?.message;

        setErrMsg(errorMessage);
      },
    });
  };

  if (clientQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (clientQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.clients.list}>Client</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.clients.list}`}>
          Client List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Client</Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="pt-3">Edit Client</h2>
      <div className="col-md-8 edit-client-wrapper pt-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ClientForm clientData={clientQuery?.data?.data} />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Save
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EditTeamGroup;
