import GroupResource from 'api/groups';
import TeamGroupForm from 'components/teams/TeamGroupForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const AddTeamGroup: React.FC = () => {
  let teamGroupAPI = new GroupResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const addTeamGroup = useMutation((data: any) => teamGroupAPI.store(data), {
    onSuccess: (res: any) => {
      TrackifyToast.success('Group has been added');
      history.push({
        pathname: routes.teams.list,
        search: '?name=groups',
      });
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.message
        : error?.response?.data?.error?.message;

      setErrMsg(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    addTeamGroup.mutate(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.teams.list}>Team</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.teams.list}?name=groups`}>
          Group List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Group</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Create Group</h2>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <TeamGroupForm />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Add
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddTeamGroup;
