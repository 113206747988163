import TimeSheetResource from 'api/timesheet';
import { Pagination } from 'components/common/Pagination';
import TimeSheetSkeletonLoader from 'components/common/TimeSheetSkeletonLoader';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { getWeekDate } from 'utils';
import TimeTrackerLogItem from './TimeTrackerLogItem';
interface FilterParams {
  currentPage: number;
  pageSize: number;
}

interface Props {
  isAdded: Boolean;
  setIsAdded: Function;
  userId?: number;
  selectedRole?: string;
}

const TimeTrackerLog: React.FC<Props> = (props) => {
  const { isAdded, setIsAdded, userId, selectedRole } = props;
  let timeSheetAPI = new TimeSheetResource();
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: 20,
  });
  const queryClient = useQueryClient();

  const [weeks, setWeeks] = useState<Array<Object>>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const queryList = useQuery(
    [
      'timeSheetList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        userId: userId,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      const response = await timeSheetAPI.list(queryParams, userId);

      const weekTotal = [];

      for (let i = 0; i < response.data.data.length; i++) {
        for (let j = 0; j < response.data.week_map.length; j++) {
          if (
            new Date(response.data.data[i].date) <
            new Date(response.data.week_map[j].week_end) &&
            new Date(response.data.data[i].date) >=
            new Date(response.data.week_map[j].week_start)
          ) {
            weekTotal.push(response.data.week_map[j]);
          }
        }
      }




      // setWeeks([...new Set(weekTotal)]);

      return { ...response.data, weeks: [...new Set(weekTotal)] };
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: inProgressTimeSheet,
    isLoading: inProgressTimeSheetLoading,
    refetch: refetchInProgress,
  } = useQuery(
    ['inProgressTimeSheet'],
    async () => {
      const response = await timeSheetAPI.inProgressTimeSheet();
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const { data: timeSheetList, isLoading: isTimeSheetListLoading } = queryList;






  useEffect(() => {
    if (isAdded) {
      setFilterParams((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
      setIsAdded(false);
    }
    refetchInProgress();

    // eslint-disable-next-line
  }, [isAdded]);

  useEffect(() => {
    if (userId) {
      setFilterParams((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    }
    // eslint-disable-next-line
  }, [userId]);

  //get User details
  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  return (
    <>
      {timeSheetList?.weeks?.length > 0 &&
        timeSheetList?.weeks?.map((weekTotal: any, index: number) => (
          <React.Fragment key={weekTotal.week_start}>
            <div className="d-flex justify-content-between px-2 pt-3 total-week-log-time-wrap">
              <span>
                {getWeekDate(weekTotal.week_start, weekTotal.week_end)}
              </span>
              <span>
                Week total: <strong>{weekTotal.total_duration}</strong>
              </span>
            </div>
            {timeSheetList?.data?.map((logData: any, index: number) => {
              const difference = dayjs().diff(dayjs(logData.date), 'days');
              const lockTimeSheet =
                userDetail.role_id === 4 ? difference > 2 : false;

              return (
                new Date(logData.date) <= new Date(weekTotal.week_end) &&
                new Date(logData.date) >= new Date(weekTotal.week_start) && (
                  <React.Fragment key={logData.date}>
                    <TimeTrackerLogItem
                      logData={logData}
                      editing={editing ? editing : lockTimeSheet}
                      setEditing={setEditing}
                      inProgress={!!inProgressTimeSheet}
                      selectedRole={selectedRole}
                    />
                  </React.Fragment>
                )
              );
            })}
          </React.Fragment>
        ))}
      {queryList?.data?.data?.length == 0 && (
        <div
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '100px',
          }}>
          No tracked log yet
        </div>
      )}
      {isTimeSheetListLoading && <TimeSheetSkeletonLoader rows={3} />}
      {filterParams && (
        <div className="mt-3">
          {
            <Pagination
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              dataList={queryList}
            />
          }
        </div>
      )}
    </>
  );
};

export default TimeTrackerLog;
