import { CSVFILEEXTENSION, CSVFILETYPE } from 'constants/common';
import dayjs from 'dayjs';
import * as FileSaver from 'file-saver';
import { convertTimeToDecimal } from 'utils';
import * as XLSX from 'xlsx';

interface FilterParams {
  group_type: 'user' | 'project' | 'month' | 'description';
  group_by_keyword: 'none' | 'user' | 'project' | 'month' | 'description';
}

/* Export the data filtering by group type and it's keyword */

export const prepareExcelData = (filterParams: FilterParams, reports: any) => {
  let excelData;
  let newData: any = [];

  if (filterParams.group_by_keyword === 'none') {
    excelData = reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'user')
        return {
          User: res?.name,
          'Time (Hr)': res?.duration,
        };
      else if (filterParams?.group_type === 'project')
        return {
          Project: res?.name,
          'Time (Hr)': res?.duration,
        };
      else if (filterParams?.group_type === 'month')
        return {
          Month: res?.name,
          'Time (Hr)': res.duration,
        };
      else if (filterParams?.group_type === 'description')
        return {
          Description: res?.description,
          'Time (Hr)': res.duration,
        };
    });
  } else if (filterParams.group_by_keyword === 'description') {
    reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'user') {
        if (res.children.length > 0) {
          newData.push({
            User: res.name,
            Description: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration), // convert to hour decimal
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Description: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': convertTimeToDecimal(subReport.duration),
          });
        });
        excelData = newData;
      } else if (filterParams?.group_type === 'project') {
        if (res.children.length > 0) {
          newData.push({
            Project: res.name,
            Description: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Description: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      } else if (filterParams?.group_type === 'month') {
        if (res.children.length > 0) {
          newData.push({
            Month: res.name,
            Description: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Description: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      }
    });
    excelData = newData;
  } else if (filterParams.group_by_keyword === 'user') {
    reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'project') {
        if (res.children.length > 0) {
          newData.push({
            Project: res.name,
            User: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            User: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      } else if (filterParams?.group_type === 'month') {
        if (res.children.length > 0) {
          newData.push({
            Month: res.name,
            User: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            User: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      }
    });
    excelData = newData;
  } else if (filterParams.group_by_keyword === 'month') {
    reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'project') {
        if (res.children.length > 0) {
          newData.push({
            Project: res.name,
            Month: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Month: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      } else if (filterParams?.group_type === 'user') {
        if (res.children.length > 0) {
          newData.push({
            User: res.name,
            Month: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Month: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      }
    });
    excelData = newData;
  } else if (filterParams.group_by_keyword === 'project') {
    reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'month') {
        if (res.children.length > 0) {
          newData.push({
            Month: res.name,
            Project: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Project: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      } else if (filterParams?.group_type === 'user') {
        if (res.children.length > 0) {
          newData.push({
            User: res.name,
            Project: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Project: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      }
    });
    excelData = newData;
  } else if (filterParams.group_by_keyword === 'date') {
    reports?.group_one.map((res: any) => {
      if (filterParams?.group_type === 'project') {
        if (res.children.length > 0) {
          newData.push({
            Project: res.name,
            Date: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Date: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      } else if (filterParams?.group_type === 'user') {
        if (res.children.length > 0) {
          newData.push({
            User: res.name,
            Date: '',
            'Time (Hr)': res.total_duration,
            'Time (Decimal)': convertTimeToDecimal(res.total_duration),
          });
        }
        res.children.map((subReport: any) => {
          newData.push({
            Date: subReport?.name,
            'Time (Hr)': subReport.duration,
            'Time (Decimal)': parseFloat(
              convertTimeToDecimal(subReport.duration)
            ),
          });
        });
      }
    });
    excelData = newData;
  }

  const ws = XLSX.utils.json_to_sheet(excelData);
  ws['!cols'] = [{ wch: 25 }, { wch: 25 }, { wch: 25 }];
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });
  const data = new Blob([excelBuffer], { type: CSVFILETYPE });
  FileSaver.saveAs(data, `Report-${dayjs(new Date())}` + CSVFILEEXTENSION);
};
