import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { checkPermissions } from 'utils';

interface Props {
  component: React.FC<RouteComponentProps>;
  userPermissions?: any;
  path: string | string[];
  exact?: boolean;
}

const RoleBasedRouting: React.FC<Props> = ({
  userPermissions,
  component: Component,
  path,
  exact = false,
}) => {
  const history = useHistory();

  const permitted = checkPermissions(
    userPermissions,
    userPermissions?.is_manager
  );

  const handlingNoAccess = () => {
    history.goBack();
    return null;
  };

  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) =>
          permitted ? <Component {...props} /> : handlingNoAccess()
        }
      />
    </>
  );
};

export default connect((state: any) => ({
  userPermissions: state?.data?.auth?.user,
}))(RoleBasedRouting);
