import routes from 'constants/routes';
import React from 'react';
import { Breadcrumb, Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import TeamMemberGroupList from './TeamGroupList';
import TeamMemberList from './TeamMemberList';

const TeamPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultActiveKey = params.get('name') || 'members';

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.teams.list}>Team</Breadcrumb.Item>
        <Breadcrumb.Item>Team List</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Team</h2>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        transition={false}
        id="noanim-tab-example"
        className="mt-3">
        <Tab eventKey="members" title="Members">
          <TeamMemberList />
        </Tab>
        <Tab eventKey="groups" title="Groups">
          <TeamMemberGroupList />
        </Tab>
      </Tabs>
    </>
  );
};

export default TeamPage;
