import { setReportsDatePreset, setReportsFilter } from 'actions/data/reports';
import ProjectResource from 'api/projects';
import ReportResource from 'api/reports';
import UserResource from 'api/user';
import { Chart, registerables } from 'chart.js';
import { MultiSelectAll } from 'components/common/MultiSelectAll';
import { Pagination } from 'components/common/Pagination';
import AccordComponent from 'components/reports/AccordComponent';
import BarComponent from 'components/reports/BarComponent';
import DetailedReport from 'components/reports/DetailedReport';
import NoneComponent from 'components/reports/NoneComponent';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import presets from 'constants/datePickerPresets';
import dayjs from 'dayjs';
import React, { forwardRef, useState } from 'react';
import { Breadcrumb, Button, Card } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DateFormatYMD,
  getDateOnly,
  getDayRange,
  getTime,
  getWeekRange,
} from 'utils';

Chart.register(...registerables);
interface FilterParams {
  currentPage: number;
  pageSize: number;
  type: string;
  only_ot: string;
  group_type: any;
  group_by_keyword: any;
  project_id: any;
  user_id: any;
  date_from: string;
  date_to: string;
}

interface ISubmitGroupBy {
  type: 'summary' | 'detailed';
  group_by_keyword?: string;
  group_type?: string;
}

interface ISelectedUser {
  id: number;
  name: string;
}

const CreatedAtInput = forwardRef((props: any) => {
  return <input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

const ReportsScreen: React.FunctionComponent = () => {
  const { reportsDetail, datePreset, userDetail } = useSelector(
    (state: any) => ({
      reportsDetail: state?.data?.reports?.reportsFilter,
      datePreset: state?.data?.reports?.reportDatePreset,
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [barReportData, setBarReportData] = useState();
  const [barReportLabel, setBarReportLabel] = useState();
  // active preset is used to highlight active date shortcuts
  const [activePreset, setActivePreset] = useState<number>(
    datePreset > -1 ? datePreset : -1
  );

  const [type, setType] = useState<string>(
    reportsDetail?.type?.length > 0 ? reportsDetail.type : 'summary'
  );
  const [onlyOt, setOnlyOt] = useState<string>(
    reportsDetail?.only_ot ? reportsDetail.only_ot : 'no'
  );
  let projectAPI = new ProjectResource();
  let userAPI = new UserResource();
  let reportAPI = new ReportResource();
  const methods = useForm<any>();

  const [selectedUser, setSelectedUser] = useState(
    reportsDetail?.user_id?.length > 0 ? reportsDetail.user_id : []
  );
  const [selectedProject, setSelectedProject] = useState(
    reportsDetail?.project_id?.length > 0 ? reportsDetail.project_id : []
  );
  const [startDate, setStartDate] = useState<Date | null>(
    reportsDetail?.date_from?.length > 0
      ? new Date(reportsDetail.date_from)
      : new Date(getWeekRange(new Date()).week_start)
  );
  const [groupByKeyWord, setGroupByKeyWord] = useState<String | null>(
    reportsDetail?.group_by_keyword?.length > 0
      ? reportsDetail.group_by_keyword
      : 'user'
  );

  const [endDate, setEndDate] = useState<Date | null>(
    reportsDetail?.date_to?.length > 0
      ? new Date(reportsDetail.date_to)
      : new Date(getDayRange(new Date()).day_end)
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    type,
    only_ot: onlyOt,
    group_by_keyword: groupByKeyWord,
    group_type:
      reportsDetail?.group_type?.length > 0
        ? reportsDetail.group_type
        : 'project',
    project_id:
      reportsDetail?.project_id?.length > 0 ? reportsDetail.project_id : [],
    user_id: reportsDetail?.user_id?.length > 0 ? reportsDetail.user_id : [],
    date_from: startDate
      ? getDateOnly(startDate)
      : getWeekRange(new Date()).week_start,
    date_to: endDate ? getDateOnly(endDate) : getWeekRange(new Date()).week_end,
  });

  const userListQuery = useQuery([`filterUserList`], async () => {
    const response = await userAPI.filterUserList();
    return response?.data?.data;
  });
  const projectListtQuery = useQuery([`getAllProjects`], async () => {
    const response = await projectAPI.list({ limit: 1000 });
    return response?.data?.data;
  });

  const queryList = useQuery(
    ['summaryReport', filterParams],

    async () => {
      let queryParams: any = {
        project_id: filterParams.project_id.map((res: any) => res.id),
        user_id: filterParams.user_id.map((res: any) => res.id),
        date_from: filterParams.date_from,
        date_to: filterParams.date_to,
        only_ot: filterParams.only_ot,
      };
      let response: any;
      if (filterParams.type === 'summary') {
        let groupParams: any = [];

        groupParams.push(filterParams.group_type.toUpperCase());
        if (!['none', ''].includes(filterParams.group_by_keyword))
          groupParams.push(filterParams.group_by_keyword.toUpperCase());
        queryParams.group = groupParams;

        response = await reportAPI.summary(queryParams);
        setBarReport(response?.data?.dateAndTotalTime);
      } else {
        if (queryParams.user_id.length <= 0) delete queryParams.user_id;
        if (queryParams.project_id.length <= 0) delete queryParams.project_id;
        queryParams.page = filterParams.currentPage;
        queryParams.limit = filterParams.pageSize;
        response = await reportAPI.detailed(queryParams);
      }
      dispatch(setReportsFilter(filterParams));

      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: reports, isLoading: isReportsLoading } = queryList;

  const setBarReport = (reportData: any) => {
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const dayCount = date2.diff(date1, 'day');
    let data: any = [];
    let labels: any = [];
    for (let i = 0; i < dayCount + 1; i++) {
      const dates = dayjs(startDate).add(i, 'day').format('YYYY-MM-DD');
      labels.push(dates);
      const filterData = reportData?.filter((res: any) => {
        return dates === res.date;
      });

      if (filterData.length > 0) {
        data.push(filterData[0]);
      } else {
        data.push({
          billable_duration: 0,
          color: '',
          duration: 0,
          label: '',
          project_name: '',
        });
      }
    }
    setBarReportLabel(labels);
    setBarReportData(data);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return 'Select Date Range';
    if (!eDate) return `${DateFormatYMD(sDate)} - Select End Date`;
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const onSubmit = (data: any) => {
    if (selectedProject.length > 0) {
      data.project_id = selectedProject;
    }
    if (selectedUser?.length > 0) {
      data.user_id = selectedUser;
    }
    filterList(data);
  };

  const filterList = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      type: data.type,
      project_id: data.project_id || [],
      user_id: data.user_id || [],
      date_from: getDateOnly(startDate),
      date_to: getDateOnly(endDate),
      currentPage: 1,
      only_ot: data.only_ot,
    }));
    dispatch(setReportsDatePreset(activePreset));
  };

  /**
   * Handle reset selected project and user
   */
  const handleReset = (data: any) => {
    setSelectedProject([]);
    setSelectedUser([]);
    setStartDate(new Date());
    setEndDate(new Date());
    setActivePreset(0);
    setOnlyOt('no');
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  const onSubmitGroupBy = (data: ISubmitGroupBy) => {
    if (data.group_type === data.group_by_keyword) {
      data.group_by_keyword = 'none';
    }
    filterListGroupBy(data);
  };

  const filterListGroupBy = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      group_type: data.group_type,
      group_by_keyword: data.group_by_keyword,
    }));
  };

  const getOption = (group_type: string) => {
    if (group_type === 'user') {
      return (
        <>
          <option value="none">None</option>
          <option value="date">Date</option>
          <option value="project">Project</option>
          <option value="month">Month</option>
          <option value="description">Description</option>
        </>
      );
    } else if (group_type === 'project') {
      return (
        <>
          <option value="none">None</option>
          <option value="user">User</option>
          <option value="date">Date</option>
          <option value="month">Month</option>
          <option value="description">Description</option>
        </>
      );
    } else if (group_type === 'month') {
      return (
        <>
          <option value="none">None</option>
          <option value="user">User</option>
          <option value="project">Project</option>
          <option value="description">Description</option>
        </>
      );
    } else {
      return (
        <>
          <option value="none">None</option>
        </>
      );
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Reports</h2>
      <section className="report-filter">
        <div className="mt-5">
          <Card>
            <Card.Body>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6 col-xl-2 mt-1">
                    <select
                      className="form-control form-select"
                      {...methods.register('type')}
                      onChange={(data: any) => setType(data.target.value)}
                      defaultValue={type}
                      placeholder="Select">
                      <option value="summary">Summary</option>
                      <option value="detailed">Detailed</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-xl-2  mt-1">
                    <MultiSelectAll
                      options={projectListtQuery?.data}
                      placeholder={'Select Project'}
                      arrayValue={selectedProject}
                      setArrayValue={setSelectedProject}
                    />
                  </div>
                  <div className="col-md-6 col-xl-2  mt-1 mb-1">
                    <MultiSelectAll
                      disabled={
                        userDetail.role_id === 4 && !userDetail.is_manager
                      }
                      options={userListQuery?.data}
                      placeholder={'Select User'}
                      arrayValue={
                        userDetail.role_id === 4 && !userDetail.is_manager
                          ? userDetail?.name
                          : selectedUser
                      }
                      setArrayValue={setSelectedUser}
                    />
                  </div>
                  <div className="col-md-6 col-xl-3 mt-1 mb-1">
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => {
                        setActivePreset(-1);
                        handleDateChange(date as [Date, Date]);
                      }}
                      startDate={startDate}
                      maxDate={new Date()}
                      endDate={endDate}
                      selected={startDate}
                      monthsShown={2}
                      selectsRange
                      customInput={
                        <div
                          style={
                            !endDate
                              ? {
                                  border: '1px solid red',
                                  height: '40px',
                                  borderRadius: '5px',
                                  padding: '5px',
                                }
                              : {
                                  border: '1px solid #eee',
                                  height: '40px',
                                  borderRadius: '5px',
                                  padding: '5px',
                                }
                          }>
                          {getFormattedRangeDate(startDate, endDate)}
                        </div>
                      }
                      shouldCloseOnSelect={true}
                      autoComplete="off">
                      <div className="row w-40 days-wrapper">
                        {presets.map(({ text, start, end }, index) => {
                          return (
                            <button
                              className={
                                // active class will be added by checking the index of the preset
                                'btn btn-primary ' +
                                (activePreset === index ? 'active' : '')
                              }
                              style={{ height: '32px' }}
                              key={text}
                              type="button"
                              onClick={() => {
                                // after a preset is selected, its index will be set as activePreset,
                                // and the start and end date will change.
                                setActivePreset(index);
                                handleDateChange([
                                  start.toDate(),
                                  end.toDate(),
                                ]);
                              }}>
                              {text}
                            </button>
                          );
                        })}
                      </div>
                    </ReactDatePicker>
                  </div>
                  <div className="col-md-auto col-xl-1 mt-1 mb-1">
                    <select
                      className="form-control form-select"
                      {...methods.register('only_ot')}
                      onChange={(data: any) => setOnlyOt(data.target.value)}
                      defaultValue={onlyOt}
                      placeholder="Select">
                      <option disabled>only OT?</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-xl-2 dashboard--button--wrapper">
                    <div className="d-flex  ">
                      <div>
                        <Button
                          style={{ width: '80px', marginRight: '5px' }}
                          variant="primary"
                          disabled={!endDate}
                          type="submit">
                          Go
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          style={{ width: '80px' }}
                          className="button danger-button"
                          onClick={handleReset}>
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </div>
      </section>

      {reports?.data?.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '100px',
          }}>
          Nothing to Show
        </div>
      )}
      {reports?.dateAndTotalTime?.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '100px',
          }}>
          Nothing to Show
        </div>
      )}
      {filterParams.type === 'summary' &&
        reports?.dateAndTotalTime?.length > 0 && (
          <>
            <BarComponent
              barReportData={barReportData}
              barReportLabel={barReportLabel}
              totalTime={getTime(reports?.totals?.total_time)}
              isLegendDisplay={false}
              filterParams={filterParams}
              reports={reports}
            />
            <br />
            <form className="form-group-by">
              <div className="d-flex p-2 align-items-center">
                <p>Group by:</p>
                <div
                  className="d-flex flex-wrap"
                  style={{ marginLeft: '20px' }}>
                  <div className="d-flex ms-2">
                    <select
                      className="form-control form-select me-2 "
                      value={filterParams.group_type}
                      onChange={(e) =>
                        onSubmitGroupBy({
                          type: 'summary',
                          group_type: e.target.value,
                          group_by_keyword: filterParams.group_by_keyword,
                        })
                      }>
                      <option value="user">User</option>
                      <option value="project">Project</option>
                      <option value="month">Month</option>
                      <option value="description">Description</option>
                    </select>
                    <select
                      className="form-control form-select"
                      value={filterParams.group_by_keyword}
                      onChange={(e) =>
                        onSubmitGroupBy({
                          type: 'summary',
                          group_type: filterParams.group_type,
                          group_by_keyword: e.target.value,
                        })
                      }>
                      {getOption(filterParams.group_type)}
                    </select>{' '}
                  </div>
                </div>
              </div>
            </form>
            <section className="report-doughnet-component  bg-white">
              {filterParams.group_by_keyword === 'none' ? (
                <div className="w-100 p-3">
                  <NoneComponent group_one={reports.group_one} />
                </div>
              ) : (
                <div className="w-100 p-3">
                  <AccordComponent group_one={reports.group_one} />
                </div>
              )}
            </section>
          </>
        )}
      {filterParams.type !== 'summary' && reports?.data?.length > 0 && (
        <DetailedReport
          totalTime={getTime(reports?.total_time)}
          billableTime={getTime(reports?.billable_time)}
          reportData={reports}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          isReportsLoading={isReportsLoading}
          queryList={queryList}
        />
      )}

      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </>
  );
};
export default ReportsScreen;
