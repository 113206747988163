import Resource from 'api/resource';
import http from 'utils/http';
class GroupResource extends Resource {
  constructor() {
    super('groups');
  }

  /**
   * Assign Member to Group
   */
  assignMember(groupId: number, data: any) {
    return http({
      url: '/groups/' + groupId + '/members',
      method: 'post',
      data: data,
    });
  }
}

export { GroupResource as default };
