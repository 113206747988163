import ReportResource from 'api/reports';
import TimeSheetResource from 'api/timesheet';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { CSVFILEEXTENSION, CSVFILETYPE } from 'constants/common';
import dayjs from 'dayjs';
import * as FileSaver from 'file-saver';
import React from 'react';
import { Button } from 'react-bootstrap';
import { BiDollar, BiExport } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { durationToSec, getDateOnly, getTimeOnly, truncateString } from 'utils';
import TrackifyToast from 'utils/toast';
import * as XLSX from 'xlsx';

interface Props {
  totalTime: string;
  reportData: any;
  billableTime: any;
  filterParams: any;
  setFilterParams: Function;
  isReportsLoading: boolean;
  queryList: any;
}

const DetailedReport: React.FunctionComponent<Props> = (props) => {
  const {
    totalTime,
    reportData,
    filterParams,
    setFilterParams,
    isReportsLoading,
    billableTime,
    queryList,
  } = props;

  const timeSheetAPI = new TimeSheetResource();
  const reportAPI = new ReportResource();
  const queryClient = useQueryClient();

  const handleExport = async () => {
    try {
      let queryParams: any = {
        project_id:
          filterParams.project_id.length > 0
            ? filterParams.project_id.map((res: any) => res.id)
            : null,
        user_id:
          filterParams.user_id.length > 0
            ? filterParams.user_id.map((res: any) => res.id)
            : null,
        date_from: filterParams.date_from,
        date_to: filterParams.date_to,
        only_ot: filterParams.only_ot
      };

      const response = await reportAPI.exportDetailedReport(queryParams);

      const detailedReport = response?.data?.data;
      let excelData;
      if (detailedReport) {
        excelData = detailedReport?.map((res: any) => {
          return {
            Project: res?.TimeSheetProject?.name,
            User: res?.TimeSheetUser?.name,
            'Task Description': res.description,
            Billable: res?.is_billable ? 'Yes' : 'No',
            'Start Date': `${getDateOnly(new Date(res.time_from))}`,
            'Start Time': `${getTimeOnly(new Date(res.time_from))}`,
            'End Date': `${getDateOnly(new Date(res.time_to))}`,
            'End Time': `${getTimeOnly(new Date(res.time_to))}`,
            Duration: res?.duration,
            'Time (Decimal)': parseFloat(
              (durationToSec(res?.duration) / 3600).toFixed(2)
            ), // convert to hour
          };
        });
      }

      const ws: any = XLSX.utils.json_to_sheet(excelData);
      ws['!cols'] = [
        { wch: 15 },
        { wch: 15 },
        { wch: 40 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
      ];

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

      // append total hours to the end of the sheet
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          // skip a row
          ['', '', '', '', '', '', '', '', '', ''],
          ['', '', '', '', '', '', '', '', 'Total Logged Hours', totalTime],
          [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Total Billable Hours',
            billableTime,
          ],
        ],
        { origin: -1 }
      );

      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
      });
      const data = new Blob([excelBuffer], { type: CSVFILETYPE });
      FileSaver.saveAs(data, `Report-${dayjs(new Date())}` + CSVFILEEXTENSION);
      TrackifyToast.success('Report data is being exported');
      return;
    } catch (e) {
      TrackifyToast.error('There was an error exporting report.');
      return;
    }
  };

  const updateTimeSheet = useMutation(
    (data: any) => {
      const id = data.id;
      delete data.id;
      return timeSheetAPI.update(id, data);
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('summaryReport');
        TrackifyToast.success('Time Sheet Updated');
      },
      onError: (error: any) => {},
    }
  );

  const handleChangeBillability = (value: any, id: number) => {
    let data: any = {};
    data.is_billable = value;
    data.id = id;

    updateTimeSheet.mutate(data);
    return;
  };

  return (
    <>
      <section className="detailed-bar-component" style={{ minHeight: '50vh' }}>
        <div
          className="d-flex justify-content-between p-2 mt-3"
          style={{ background: 'rgb(0, 107, 168)', color: '#fff' }}>
          <p>
            Total: {totalTime} | Billable: {billableTime}
          </p>
          <Button onClick={handleExport} title="Export">
            <BiExport title="Export" />
          </Button>
        </div>
        <div className="report--cotent--wrapper">
          <table style={{ width: '100%' }} className="table report---wrapper">
            <thead>
              <tr>
                <th style={{ width: '20%', textIndent: '10px' }}>Time Entry</th>
                <th style={{ width: '10%' }}>Amount</th>
                <th style={{ width: '20%' }}>User</th>
                <th style={{ width: '25%' }}>Time</th>
                <th style={{ width: '14%' }}>Duration</th>
              </tr>
            </thead>
            <tbody>
              {!isReportsLoading &&
                reportData?.data.map((res: any) => (
                  <tr key={res?.id}>
                    <td width="20%" className="description--report-wrap">
                      <p className="descriptionn" title={res?.description}>
                        {truncateString(res?.description)}
                      </p>
                      <p className="">
                        <span
                          className="dot"
                          style={{
                            backgroundColor: res?.TimeSheetProject?.color_code,
                          }}></span>
                        {res?.TimeSheetProject?.name}
                      </p>
                    </td>
                    <td style={{ textAlign: 'center' }} width="10%">
                      <BiDollar
                        style={
                          res.is_billable
                            ? { color: 'rgb(0, 107, 168)' }
                            : { color: 'gray' }
                        }
                        onClick={() =>
                          handleChangeBillability(!res.is_billable, res.id)
                        }
                      />
                    </td>
                    <td width="20%">{res?.TimeSheetUser.name}</td>
                    <td width="25%" className="time-date-wrap">
                      <p>
                        {getTimeOnly(res?.time_from)} -{' '}
                        {getTimeOnly(res?.time_to)}
                      </p>
                      <p>{getDateOnly(res?.time_to)}</p>
                    </td>
                    <td width="14%">{res?.duration}</td>
                  </tr>
                ))}
              {isReportsLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
export default DetailedReport;
