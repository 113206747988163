import ProjectResource from 'api/projects';
import TagResource from 'api/tags';
import TimeSheetResource from 'api/timesheet';
import { ReactComponent as TagIcon } from 'assets/icons/tagIcon.svg';
import dayjs from 'dayjs';
import moment, { Duration } from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Dropdown, Form, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BiDollar } from 'react-icons/bi';
import { FcOvertime } from 'react-icons/fc';
import { IoPlay } from 'react-icons/io5';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import { getCurrentUTCDateTime, getDayDate } from 'utils';
import TrackifyToast from 'utils/toast';
import { ITimeSheet } from '../../utils/interfaces/timeSheetInterface';
import Datepicker from '../common/DatePicker';
import TagList from './TagList';

interface Props {
  logData: any;
  inProgress: boolean;
  selectedRole?: string;
  editing: boolean;
  setEditing: any;
}
interface ITimeSheetItem {
  time_from: string;
  time_to: string;
  description: string;
  user_id: number;
  project_id: number;
  is_billable: boolean;
  duration: string;
  updated_by: number;
  id: number;
  is_ot: boolean;
}

type CustomToggleProps = {
  children?: any;
  onClick?: any;
};

const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => (
  <a
    style={{ textDecoration: 'none' }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}>
    {props.children}
    <span className="threedots" />
  </a>
));

CustomToggle.displayName = 'CustomToggle';
const TimeTrackerLogItem: React.FC<Props> = (props) => {
  const { logData, inProgress, selectedRole, editing, setEditing } = props;

  const projectAPI = new ProjectResource();
  const timeSheetAPI = new TimeSheetResource();
  const tagAPI = new TagResource();
  const queryClient = useQueryClient();
  const timeFromRef = useRef<HTMLInputElement>(null);
  const timeToRef = useRef<HTMLInputElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [showTag, setShowTag] = useState(false);
  const [disabled, setDisabled] = useState(inProgress);
  const [error, setError] = useState<string | null>(null);

  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 915;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
        }
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    setDisabled(inProgress);
  }, [inProgress]);

  const { data: projectList, isLoading: isProjectListLoading } = useQuery(
    ['projectList'],
    async () => {
      const response = await projectAPI.list({ status: 'active', limit: 1000 });
      return response?.data?.data;
    }
  );

  const { data: tagList, isLoading: isTagListLoading } = useQuery(
    ['tagList'],
    async () => {
      const response = await tagAPI.list({ status: true });
      return response?.data?.data;
    }
  );

  const deleteTimeSheet = useMutation((id: any) => timeSheetAPI.destroy(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('timeSheetList');
      TrackifyToast.success('Time sheet Deleted');
    },
    onError: (error: any) => {},
  });

  const handleDelete = (timeSheetId: number) => {
    deleteTimeSheet.mutate(timeSheetId);
  };

  const updateTimeSheet = useMutation(
    (data: any) => {
      const id = data.id;
      delete data.id;
      return timeSheetAPI.update(id, data);
    },
    {
      onSuccess: (res: any) => {
        // queryClient.invalidateQueries('timeSheetList');
        queryClient.setQueriesData('timeSheetList', (oldData: any) => {
          const oldWeekDuration = moment.duration(
            oldData?.weeks[0]?.total_duration
          );

          const oldDuration = moment.duration(oldData?.data[0]?.total_duration);

          let newWeeekDuration: Duration = oldWeekDuration;
          let newTodayduration: Duration = oldDuration;
          let newFormattedTodayDuration: string;
          const newData = oldData?.data?.map((items: any) => {
            return {
              ...items,
              time_sheets: items?.time_sheets.map((sheets: any) => {
                if (sheets?.id === res?.data?.data?.id) {
                  if (
                    moment.duration(res?.data?.data?.duration) <
                    moment.duration(sheets.duration)
                  ) {
                    newWeeekDuration = moment.duration(
                      oldWeekDuration.asMilliseconds() -
                        moment.duration(sheets.duration).asMilliseconds() +
                        moment
                          .duration(res?.data?.data?.duration)
                          .asMilliseconds()
                    );
                    newTodayduration = moment.duration(
                      oldDuration.asMilliseconds() -
                        moment.duration(sheets.duration).asMilliseconds() +
                        moment
                          .duration(res?.data?.data?.duration)
                          .asMilliseconds()
                    );
                    newFormattedTodayDuration = moment
                      .utc(newTodayduration?.asMilliseconds())
                      .format('HH:mm:ss');
                  } else {
                    newWeeekDuration = moment.duration(
                      oldWeekDuration.asMilliseconds() +
                        (moment
                          .duration(res?.data?.data?.duration)
                          .asMilliseconds() -
                          moment.duration(sheets.duration).asMilliseconds())
                    );
                    newTodayduration = moment.duration(
                      oldDuration.asMilliseconds() +
                        (moment
                          .duration(res?.data?.data?.duration)
                          .asMilliseconds() -
                          moment.duration(sheets.duration).asMilliseconds())
                    );
                    newFormattedTodayDuration = moment
                      .utc(newTodayduration?.asMilliseconds())
                      .format('HH:mm:ss');
                  }

                  return res.data.data;
                } else {
                  return sheets;
                }
              }),
              total_duration: newFormattedTodayDuration,
            };
          });

          const newFormattedWeekDuration = moment
            .utc(newWeeekDuration?.asMilliseconds())
            .format('HH:mm:ss');

          return {
            ...oldData,
            data: newData,
            weeks: [
              {
                ...oldData?.weeks[0],
                total_duration: newFormattedWeekDuration,
              },
              ...oldData?.weeks?.slice(1),
            ],
          };
        });

        TrackifyToast.success('Time Sheet Updated');
      },
      onError: (error: any) => {},
    }
  );

  const handleDateChange = (
    res: ITimeSheetItem,
    newDate: string,
    type: string
  ) => {
    let data: any = {};
    data.id = res.id;

    if (type === 'time_from') {
      const timeFrom = [newDate, res.time_from.split('T')[1]].join('T');
      data.time_from = new Date(timeFrom).toISOString();
    }
    if (type === 'time_to') {
      const timeTo = [newDate, res.time_to.split('T')[1]].join('T');
      data.time_to = new Date(timeTo).toISOString();
    }

    if (
      type === 'time_from' &&
      new Date(newDate) > new Date(res.time_to.split('T')[0])
    ) {
      TrackifyToast.error('Cannot exceed End Date');
      setEditing(false);
      return false;
    }
    if (
      type === 'time_to' &&
      new Date(newDate) < new Date(res.time_from.split('T')[0])
    ) {
      TrackifyToast.error('Cannot exceed start Date');
      setEditing(false);
      return false;
    }
    if (type === 'time_from' && newDate === res.time_from.split('T')[0]) {
      setEditing(false);
      return false;
    }
    if (type === 'time_to' && newDate === res.time_to.split('T')[0]) {
      setEditing(false);
      return false;
    }

    if (Object.keys(data).length > 1) {
      updateTimeSheet.mutate(data);
    }
    return;
  };

  const handleChange = (
    value: any,
    id: number,
    actualData?: any,
    type?: string
  ) => {
    setEditing(true);
    let data: any = {};
    let val = value.target ? value.target.value : value;
    data.id = id;
    if (type === 'description' && val !== actualData) {
      const description = val.trim();
      if (description === '') {
        setError(data.id);
        TrackifyToast.error('Description is not allowed to be empty');
        return;
      }
      if (description && description.length < 6) {
        TrackifyToast.error('Description must be atleaset 6 character');
        setError(data.id);
        return;
      } else if (description > 500) {
        TrackifyToast.error('Description must be less than 500 character');
        setError(data.id);
        return;
      } else {
        setError(null);
      }
      data.description = description;
    }
    if (
      type === 'description' ||
      type === 'timeFrom' ||
      (type === 'timeTo' && val == actualData)
    ) {
      setEditing(false);
    }
    if (type === 'billable') {
      data.is_billable = value;
    }

    if (type === 'ot') {
      data.is_ot = value;
    }

    if (type === 'project' && val !== actualData) {
      data.project_id = val.id;
      const projectDetail = projectList.filter(
        (project: any) => project.id === Number(val)
      );
      data.is_billable =
        projectDetail.length > 0 ? projectDetail[0].is_billable : false;
    }
    if (type === 'project' && val.id === actualData) {
      setEditing(false);
      return false;
    }

    if (Object.keys(data).length > 1) {
      updateTimeSheet.mutate(data);
      setEditing(false);
    }
    return;
  };

  // only handle time changes
  const handleTimeChange = (
    value: string,
    timeSheet: ITimeSheet,
    type: 'time_to' | 'time_from'
  ) => {
    // get the hour and minute from the selected time
    const [hour, minute] = value.split(':');

    // create dayjs time object from the existing times
    let time_from = dayjs(timeSheet.time_from);
    let time_to = dayjs(timeSheet.time_to);

    if (type === 'time_from') {
      // if time_from is being edited, set the hour and minute to the newly selected value
      time_from = time_from
        .set('hours', parseInt(hour, 0))
        .set('minutes', parseInt(minute, 0));

      if (
        time_from.toISOString() === dayjs(timeSheet.time_from).toISOString()
      ) {
        return;
      }
    } else if (type === 'time_to') {
      // if time_to is being edited, set the hour and minute to the newly selected value
      time_to = time_to
        .set('hours', parseInt(hour, 0))
        .set('minutes', parseInt(minute, 0));
      if (time_to.toISOString() === dayjs(timeSheet.time_to).toISOString()) {
        return;
      }
    }

    // calculate the duration in hours between the start and end dates
    const duration = time_to.diff(time_from, 'hours', true);

    /**
     * sometimes during editing, time_from will be bigger than time_to, so to compensate
     * for that, we add 1 day to time_to
     */
    if (time_from > time_to) {
      const date = time_to.get('date');
      const month = time_to.get('month');
      time_to = time_to.set('date', date).set('month', month);
    } else if (duration > 24) {
      // if duration is greater than 24hrs, set the time_to date to the date in time_from
      const date = time_from.get('date');
      const month = time_from.get('month');
      time_to = time_to.set('date', date).set('month', month);
    }

    const data = {
      id: timeSheet.id,
      time_from: time_from.toISOString(),
      time_to: time_to.toISOString(),
    };

    updateTimeSheet.mutate(data);
  };

  const addTimeSheet = useMutation((data: any) => timeSheetAPI.store(data), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries('timeSheetList');
    },
    onError: (error: any, response: any) => {},
  });

  const addInProgressTimeSheet = useMutation(
    (data: any) => timeSheetAPI.store(data),
    {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries('inProgressQuery');
        queryClient.invalidateQueries('inProgressTimeSheet');
      },
      onError: (error: any, response: any) => {},
    }
  );

  const handleDuplicate = (rowData: any) => {
    let data = {};

    data = {
      description: rowData.description,
      project_id: rowData.project_id,
      is_billable: rowData.is_billable,
      time_from: new Date(rowData.time_from).toISOString(),
      time_to: new Date(rowData.time_to).toISOString(),
      is_ot: rowData.is_ot,
      tags: rowData.TimeSheetTimeSheetTag.map((tag: any) => tag.tag_id),
    };

    if (userDetail.role_id === 1 || userDetail.role_id === 2) {
      data = { ...data, user_id: rowData.user_id };
    }
    addTimeSheet.mutate(data);
  };

  const handleContinueTimer = (rowData: any) => {
    if (!rowData) {
      TrackifyToast.error('You have active timer running.');
      return;
    }
    const data = {
      description: rowData.description,
      project_id: rowData.project_id,
      is_billable: rowData.is_billable,
      is_ot: rowData.is_ot,
      time_from: getCurrentUTCDateTime(),
      tags: rowData.TimeSheetTimeSheetTag.map((tag: any) => tag.tag_id),
    };
    addInProgressTimeSheet.mutate(data);
  };

  const popover = (res: any) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body style={{ width: '350px', height: '175px' }}>
          <TagList
            id={res.id}
            tagListData={tagList}
            tagData={res.TimeSheetTimeSheetTag}
          />
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <Card className="mt-3 time-card-wrapper" key={logData.id}>
        <Card.Header
          className="d-flex justify-content-between p-2 total-log-time-wrap"
          style={{ background: '#E4EAEE' }}>
          <span>{getDayDate(logData.date)}</span>
          <span>
            Total: <strong>{logData.total_duration}</strong>
          </span>
        </Card.Header>
        <Card.Body className="p-0">
          {logData?.time_sheets.map((res: any, index: any) => {
            const isProjectDisabled = projectList?.find(
              (p: any) => p.id === res?.TimeSheetProject?.id
            );

            return (
              <React.Fragment key={res.id}>
                <div
                  key={res.id}
                  className="row g-0 p-2 mt-1 border-bottom time-traker-wrap">
                  <div
                    className={
                      userDetail.role_id == 4
                        ? 'col-xl-3 col-md-12 mt-1'
                        : 'col-xl-12 col-md-12 mt-1'
                    }>
                    <Form.Control
                      key={index}
                      type="text"
                      defaultValue={res.description}
                      className={error == res.id ? 'is-invalid' : ''}
                      placeholder="What are you working on ?"
                      required={true}
                      onBlur={(e) =>
                        handleChange(e, res.id, res.description, 'description')
                      }
                      disabled={editing && error != res.id}
                    />
                  </div>
                  <div
                    className={
                      userDetail.role_id === 4
                        ? 'col-xl-2 col-md-12 mt-1'
                        : 'col-xl-12 col-md-12 mt-1'
                    }>
                    <Select
                      placeholder="Select Project"
                      id="project_id"
                      options={projectList}
                      onChange={(e: any) =>
                        handleChange(e, res.id, res.project_id, 'project')
                      }
                      value={res?.TimeSheetProject}
                      isSearchable={true}
                      isLoading={isProjectListLoading}
                      escapeClearsValue
                      isDisabled={
                        userDetail.role_id === 4
                          ? editing || !isProjectDisabled
                          : editing
                      }
                      getOptionLabel={(options: any) => options['name']}
                      getOptionValue={(options: any) => options['id']}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '25px',
                          borderColor: '#C6D2D9',
                        }),
                        input: (baseStyles, state) => ({
                          ...baseStyles,
                          margin: '-5px',
                        }),
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  <div
                    className={
                      isMobile
                        ? 'col-md-12 mt-2 time-picker-wrapper'
                        : 'col-xl-4 col-md-12 time-picker-wrapperm ' &&
                          userDetail.role_id == 4
                        ? 'col-xl-3 col-md-12 time-picker-wrapper'
                        : 'col-xl-12 col-md-12  time-picker-wrapper'
                    }>
                    <div className="row g-0 time-dash-wrapper mt-1 mb-1 p-2">
                      <div
                        className={
                          userDetail.role_id == 4
                            ? 'col-sm-5 col-md-5 col-xl-5 start-schedule-wrap'
                            : 'col-sm-6 col-md-6 col-xl-3 col-lg-6 start-schedule-wrap'
                        }>
                        <div className="row">
                          <div
                            className={
                              isMobile
                                ? 'col-md-12 time-schedule-wrap'
                                : 'col-md-12 time-schedule-wrap'
                            }>
                            <Form.Control
                              type="time"
                              required
                              ref={timeFromRef}
                              defaultValue={dayjs(res.time_from).format(
                                'HH:mm'
                              )}
                              disabled={editing}
                              onBlur={(e) =>
                                handleTimeChange(
                                  e.target.value,
                                  res,
                                  'time_from'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <HideControl
                        renderNoAccess={(data: any) => {
                          return data ? data : '';
                        }}>
                        <div
                          className="col-sm-6 col-md-6 col-xl-2 col-lg-6 "
                          style={
                            isMobile
                              ? {
                                  display: 'block',
                                  textAlign: 'center',
                                  marginTop: '0px',
                                }
                              : { textAlign: 'center' }
                          }>
                          <div
                            className="datepicker-wrap"
                            style={
                              isMobile
                                ? {
                                    border: '1px solid #C6D2D9',
                                    borderRadius: '4px',
                                  }
                                : {
                                    border: '1px solid #C6D2D9',
                                    borderRadius: '4px',
                                  }
                            }>
                            <Datepicker
                              selected={dayjs(res.time_from).toDate()}
                              disabled={editing}
                              onChange={(date) =>
                                handleDateChange(
                                  res,
                                  dayjs(date)
                                    .format('YYYY-MM-DD')
                                    .split('T')[0],
                                  'time_from'
                                )
                              }
                              onKeyDown={(e) => e.preventDefault()}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </HideControl>
                      <div
                        className={
                          userDetail.role_id == 4
                            ? 'dash-wrap col-sm-2 col-md-2 col-xl-2'
                            : 'dash-wrap col-sm-12 col-md-12 col-lg-12 col-xl-2'
                        }
                        style={
                          isMobile
                            ? { display: 'block', textAlign: 'center' }
                            : { textAlign: 'center' }
                        }>
                        -
                      </div>
                      <div
                        className={
                          userDetail.role_id == 4
                            ? 'col-sm-5 col-md-5 col-xl-5 end-schedule-wrap '
                            : 'col-sm-6 col-md-6 col-lg-6 col-xl-3 end-schedule-wrap'
                        }>
                        <div className="row ">
                          <div
                            className={
                              isMobile
                                ? 'col-md-12 time-schedule-wrap'
                                : 'col-md-12 time-schedule-wrap'
                            }>
                            <Form.Control
                              type="time"
                              required
                              ref={timeToRef}
                              defaultValue={dayjs(res.time_to).format('HH:mm')}
                              disabled={editing}
                              onBlur={(e) =>
                                handleTimeChange(e.target.value, res, 'time_to')
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <HideControl
                        renderNoAccess={(data: any) => {
                          return data ? data : '';
                        }}>
                        <div
                          className="col-sm-6 col-md-6 col-lg-6 col-xl-2 "
                          style={
                            isMobile
                              ? {
                                  display: 'block',
                                  textAlign: 'center',
                                  marginTop: '0px',
                                }
                              : { textAlign: 'center' }
                          }>
                          <div
                            className="datepicker-wrap"
                            style={
                              isMobile
                                ? {
                                    border: '1px solid #C6D2D9',
                                    borderRadius: '4px',
                                  }
                                : {
                                    border: '1px solid #C6D2D9',
                                    borderRadius: '4px',
                                  }
                            }>
                            <Datepicker
                              selected={dayjs(res.time_to).toDate()}
                              disabled={editing}
                              onChange={(date) =>
                                handleDateChange(
                                  res,
                                  dayjs(date)
                                    .format('YYYY-MM-DD')
                                    .split('T')[0],
                                  'time_to'
                                )
                              }
                              onKeyDown={(e) => e.preventDefault()}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </HideControl>
                    </div>
                  </div>
                  <div
                    className={
                      userDetail.role_id === 4
                        ? 'right-content-wrapper col-xl-4 col-md-12'
                        : 'col-xl-12 col-md-12'
                    }>
                    <div className="time-dashboard-item-wrap">
                      <div className="total-time--wrap">
                        <div className="col-md-1 mt-1 mb-1">
                          <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="right"
                            overlay={popover(res)}>
                            <Button
                              className="tag-btn-wrap"
                              style={{
                                boxShadow: 'none',
                                background: 'none',
                                border: 'none',
                              }}
                              onClick={() => {
                                setShowTag(!showTag);
                              }}
                              disabled={editing}>
                              <TagIcon />
                            </Button>
                          </OverlayTrigger>
                        </div>
                        {(userDetail.role_id === 1 ||
                          userDetail.role_id === 2) &&
                        selectedRole === 'team' ? (
                          ''
                        ) : (
                          <div className="col-md-1 mt-1 mb-1">
                            {disabled}
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip1">Continue Timer</Tooltip>
                              }>
                              <button
                                style={{
                                  boxShadow: 'none',
                                  background: 'none',
                                  border: 'none',
                                }}
                                disabled={
                                  disabled || editing || !isProjectDisabled
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handleContinueTimer(!!disabled ? false : res);
                                  setDisabled(true);
                                }}>
                                <IoPlay style={{ marginLeft: '6px' }} />
                              </button>
                            </OverlayTrigger>
                          </div>
                        )}
                        <h4 className="col-md-2 px-1 mt-1 mb-1">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip1">Billable</Tooltip>}>
                            <Button
                              className="bg-white border-0"
                              style={
                                res.is_billable
                                  ? { color: 'rgb(0, 107, 168)' }
                                  : { color: 'gray' }
                              }
                              onClick={() =>
                                handleChange(
                                  !res.is_billable,
                                  res.id,
                                  null,
                                  'billable'
                                )
                              }
                              disabled={editing}>
                              <BiDollar />
                            </Button>
                          </OverlayTrigger>
                        </h4>
                        <h4 className="col-md-2 px-1 ot-parent mt-1 mb-1">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip1">OT</Tooltip>}>
                            <Button
                              className="btn-light ot-btn-list"
                              style={
                                !!res.is_ot
                                  ? {
                                      borderColor: 'rgb(0, 107, 168)',
                                      opacity: '1',
                                    }
                                  : {
                                      borderColor: '#f5f5f5',
                                      opacity: '0.6',
                                    }
                              }
                              onClick={() => {
                                handleChange(!res.is_ot, res.id, null, 'ot');
                              }}
                              disabled={editing}>
                              <FcOvertime />
                            </Button>
                          </OverlayTrigger>
                        </h4>
                        <div
                          className="col-md-4 mt-1 mb-1 total-update-time-wrap"
                          style={{ whiteSpace: 'nowrap' }}>
                          <p style={{ marginBottom: 0 }}>{res.duration}</p>
                        </div>
                        <div className="col-md-2">
                          <Dropdown style={{ textAlign: 'right' }}>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => handleDuplicate(res)}
                                disabled={
                                  editing || !isProjectDisabled ? true : false
                                }>
                                Duplicate
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(res.id)}
                                disabled={editing}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
};

export default TimeTrackerLogItem;
