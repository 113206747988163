import Resource from 'api/resource';
import http from 'utils/http';
class UserResource extends Resource {
  constructor() {
    super('users');
  }

  /**
   * Upload profile
   */
  uploadProfile(data: any) {
    return http({
      url: '/auth/profile-picture',
      method: 'post',
      data: data,
    });
  }

  /**
   * User list filter
   */
  filterUserList() {
    return http({
      url: '/users/filter-user-list',
      method: 'get',
    });
  }

  /**
   * User Related Projects
   */
  userProjects(userId: number) {
    return http({
      url: `/users/${userId}/projects`,
      method: 'get',
    });
  }

  resendEmailVerification(userId: number) {
    return http({
      url: `/users/${userId}/resend-invitation`,
      method: 'post',
    });
  }
}

export { UserResource as default };
