import ClientResource from 'api/clients';
import ClientForm from 'components/clients/ClientForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const AddClient: React.FC = () => {
  let clientAPI = new ClientResource();

  const [errMsg, setErrMsg] = useState<string>('');

  const methods = useForm<any>();

  const addClient = useMutation((data: any) => clientAPI.store(data), {
    onSuccess: (res: any) => {
      TrackifyToast.success('Client created sucessfully');
      history.push(routes.clients.list);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.message
        : error?.response?.data?.error?.message;

      setErrMsg(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    addClient.mutate(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.clients.list}>Client</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.clients.list}`}>
          Client List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Client</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Create Client</h2>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ClientForm />

            <Button
              variant="primary"
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Add
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddClient;
