import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import { useFormContext } from 'react-hook-form';

interface Props {
  tagData?: any;
}

const TagForm: React.FC<Props> = (props) => {
  const { tagData } = props;

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  const [color, setColor] = useColor('hex', tagData ? tagData.color_code : '');

  const handleChange = (e: any) => {
    setColor(e);
    setValue('color_code', e.hex);
  };

  // color picker
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <>
      <form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextTagName">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              className={errors?.name ? 'is-invalid' : ''}
              defaultValue={tagData?.name}
              placeholder="Tag Name"
              maxLength={24}
              {...register('name', {
                required: 'Tag Name must be between 1 to 24 characters',
                minLength: {
                  value: 1,
                  message: 'Must be atleast 1 character',
                },
                pattern: /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name && errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextColor">
          <Form.Label column sm="2">
            Color
          </Form.Label>
          <Col sm="10" md="6">
            <Form.Control
              type="hidden"
              defaultValue={color.hex}
              value={color.hex}
              placeholder="Color Code"
              {...register('color_code', {
                required: 'Please enter colorCode',
              })}
            />
            <span
              onClick={() => handleColorClick()}
              style={{
                display: 'inline-block',
                backgroundColor: color.hex,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                marginTop: '2%',
              }}></span>
            {displayColorPicker ? (
              <div style={{ position: 'absolute', zIndex: '2' }}>
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={handleColorClose}
                />
                <ColorPicker
                  width={250}
                  height={150}
                  color={color}
                  onChange={(e) => handleChange(e)}
                  hideHSV
                  hideRGB
                  dark
                />
              </div>
            ) : null}
          </Col>
        </Form.Group>
      </form>
    </>
  );
};

export default TagForm;
