import tagResource from 'api/tags';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BiEdit } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import TrackifyToast from 'utils/toast';

interface Props {
  tagData: any;
}

const TagListItem: React.FC<Props> = (props) => {
  const { tagData } = props;
  const tagAPI = new tagResource();
  const [show, setShow] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const queryClient = useQueryClient();

  const handleClose = () => {
    setDeleteErrorMsg('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const deleteTag = useMutation(
    (data: any) => tagAPI.update(tagData?.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listTag');
        handleClose();
        TrackifyToast.success('Tag Updated');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onDeleteConfirm = () => {
    const status = tagData?.status === true ? false : true;
    deleteTag.mutate({ status: status });
  };

  return (
    <>
      <tr key={tagData.id}>
        <td
          style={
            tagData?.status === false
              ? { textDecoration: 'line-through' }
              : { textDecoration: 'none' }
          }>
          {tagData?.name}
        </td>
        <td>
          <span
            style={{
              display: 'inline-block',
              backgroundColor: tagData.color_code,
              borderRadius: '50%',
              width: '20px',
              height: '20px',
            }}></span>
        </td>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <td>
            <Form>
              <Form.Check
                type="switch"
                className="custom-control-input"
                id="custom-switch"
                onChange={handleShow}
                checked={tagData?.status}
              />
            </Form>
          </td>
          <td className="action-buttons">
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
              to={routes.tags.edit.replace(':id', tagData.id.toString())}>
              <BiEdit />
            </RouterLink>
          </td>
        </HideControl>
      </tr>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {tagData?.status === true ? 'Inactive' : 'Active'} Tag
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteErrorMsg && (
            <p className="alert-danger p-2">{deleteErrorMsg}</p>
          )}
          Are you sure you want to{' '}
          {tagData?.status === true ? 'Inactive' : 'Active'} it ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDeleteConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TagListItem;
