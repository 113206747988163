import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(duration);
export const formatTrackerTime = (timer: number) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${Number(minutes) % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const getCurrentUTCDateTime = () => {
  const date = new Date();

  return date.toUTCString();
};

export const getTimeDiffInSec = (current_date: any, date_to: any) => {
  const currentDate = dayjs(current_date);
  const endDate = dayjs(date_to);
  const diffInSec = currentDate.diff(endDate, 'seconds');
  return diffInSec;
};

export const getTime = (sec: any) => {
  var hours = Math.floor(sec / 3600);
  var minutes = Math.floor((sec - hours * 3600) / 60);
  var seconds = sec - hours * 3600 - minutes * 60;

  return [hours, minutes, seconds]
    .map(function (i) {
      return i.toString().length === 2 ? i : '0' + i;
    })
    .join(':');
};

export const getDuration = (current_date: any, date_to: any) => {
  const sec = getTimeDiffInSec(current_date, date_to) || 0;
  return getTime(sec);
};

export const getDateOnly = (dateData: any) => {
  return dayjs(dateData).format('YYYY-MM-DD');
};

export const getNextDateOnly = (dateData: any) => {
  return dayjs(dateData).format('YYYY-MM-DD');
};

export const getDayDate = (date: any) => {
  let today: any = new Date();
  let yesterday: any = new Date();
  yesterday = getDateOnly(yesterday.setDate(today.getDate() - 1));
  today = getDateOnly(today);

  return today === date
    ? 'Today'
    : yesterday === date
    ? 'Yesterday'
    : dayjs(date).format('ddd, MMM D');
};

export const getWeekDate = (startDate: any, endDate: any) => {
  const today: any = new Date();
  const thisWeek = getWeekRange(today);

  let lastWeekToday: any = new Date();
  lastWeekToday.setDate(today.getDate() - 7);

  const lastWeek = getWeekRange(lastWeekToday);

  return thisWeek.week_start === startDate
    ? 'This week'
    : lastWeek.week_start === startDate
    ? 'Last Week'
    : `${dayjs(startDate).format(' MMM D')} - ${dayjs(endDate).format(
        ' MMM D'
      )}`;
};

/**
 * get given date's week range
 * @param {String} date
 */
export function getWeekRange(date: any) {
  date = new Date(date);
  var day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  let weekStart = new Date(date.setDate(diff));
  let weekEnd = new Date(date.setDate(weekStart.getDate() + 7));

  return { week_start: getDateOnly(weekStart), week_end: getDateOnly(weekEnd) };
}

//Get initailly today's date for datepicker in dashboard and reports
export function getDayRange(date: any) {
  let dayStart = new Date();
  let dayEnd = new Date();

  return { day_start: getDateOnly(dayStart), day_end: getDateOnly(dayEnd) };
}
export const DateFormatYMD = (date: any): string => {
  if (!date) return date;
  return dayjs(date).format('YYYY/MM/DD');
};

export const getRandomColor = () => {
  let r = () => (Math.random() * 256) >> 0;
  return `rgb(${r()}, ${r()}, ${r()})`;
};

export const getTimeOnly = (date: any): string => {
  return dayjs(date).format('LTS');
};

export const durationToSec = (duration: any): number => {
  return duration.split(':').reduce((hour: any, min: any) => 60 * hour + +min);
};

/**
 * Trunacte string and add ellipsis
 * @param str
 * @returns
 */
export function truncateString(str: string, count: number = 25) {
  if (str) return str.slice(0, count) + (str.length > count ? '...' : '');
}

/**
 * Filters the members array in the project details by checking if the member logged in is assigned to the project or not
 * @param projectData The project Details
 * @param userId Id of user from redux store
 * @returns project details with the members array filtered
 */

export function filterProjectMemberByProject(projectData: any, userId: number) {
  return {
    ...projectData,
    members:
      projectData.members && projectData?.members.length > 0
        ? projectData?.members.filter(
            (m: any) => m.project_id === projectData?.id && m.user_id === userId
          )
        : [],
  };
}

export function checkPermissions(userPermissions: any, isManager: boolean) {
  // if user is superadmin/admin
  if (userPermissions.role_id === 1 || userPermissions.role_id === 2) {
    return true;
  }

  // if user has project manager role and is manager in that project
  if (isManager) {
    return true;
  }

  return false;
}

export const convertTimeToDecimal = (time: string) => {
  const [h, m, s] = time.split(':').map((val) => parseInt(val, 0));

  const timeInDecimal = dayjs
    .duration({ hours: h, minutes: m, seconds: s })
    .asHours()
    .toFixed(2);

  return timeInDecimal;
};
