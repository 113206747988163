import {
  setDashboardDatePreset,
  setDashboardFilter,
} from 'actions/data/dashboard';
import ProjectResource from 'api/projects';
import ReportResource from 'api/reports';
import { Chart, registerables } from 'chart.js';
import TeamActivities from 'components/reports/TeamActivities';
import presets from 'constants/datePickerPresets';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Breadcrumb, Button, Card } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import HideControl from 'services/HideControl';
import {
  DateFormatYMD,
  getDateOnly,
  getDayRange,
  getRandomColor,
  getTime,
  getWeekRange,
} from 'utils';
import { MultiSelectAll } from '../../../components/common/MultiSelectAll';
import DoughnutComponent from '../../../components/reports/DoughnutComponent';
import GroupBarComponent from '../../../components/reports/GroupBarComponent';
import { ADMIN_ROLES } from '../../../constants/common';

Chart.register(...registerables);

interface FilterParams {
  type: string;
  access: string;
  date_from: string;
  date_to: string;
  project_id: IProject[];
  location: string;
  timer_status: string;
}

export interface IProject {
  id: number;
  name: string;
  privacy: string;
  status: string;
  color_code: string;
  client_id?: number;
  is_billable: boolean;
  client?: Client;
  members: Member[];
}

export interface Client {
  id: number;
  name: string;
}

export interface Member {
  id: number;
  user_id: number;
  project_id: number;
  status: boolean;
  is_manager: boolean;
  created_by: number;
  updated_by: any;
}

const SplashScreen: React.FunctionComponent = () => {
  const { dashboardDetail, userRole, user, datePreset } = useSelector(
    (state: any) => ({
      dashboardDetail: state?.data?.dashboard?.dashboardFilter,
      userRole: state?.data?.auth?.user?.role_id,
      user: state?.data?.auth?.user,
      datePreset: state?.data?.dashboard?.dashboardDatePreset,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [activePreset, setActivePreset] = useState<number>(
    datePreset > -1 ? datePreset : -1
  );

  const [startDate, setStartDate] = useState<Date | null>(
    new Date(dashboardDetail?.date_from || getDayRange(new Date()).day_start)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(dashboardDetail?.date_to || getDayRange(new Date()).day_end)
  );
  const [type, setType] = useState<string>(
    dashboardDetail?.type?.toLowerCase() || 'project'
  );

  const [location, setLocation] = useState<string>('all');
  const [timerStatus, setTimerStatus] = useState<string>('all');

  const [access, setAccess] = useState<string>(
    userRole === 1 || user?.is_manager
      ? 'team'
      : dashboardDetail?.access?.toLowerCase() || 'me'
  );

  const [selectedProject, setSelectedProject] = useState([]);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    type: type,
    access: access,
    location: location,
    timer_status: timerStatus,
    date_from: startDate
      ? getDateOnly(startDate)
      : getWeekRange(new Date()).week_start,
    date_to: endDate ? getDateOnly(endDate) : getWeekRange(new Date()).week_end,
    project_id:
      dashboardDetail?.project_id?.length > 0
        ? dashboardDetail?.project_id
        : [],
  });
  let reportAPI = new ReportResource();
  let projectAPI = new ProjectResource();

  const methods = useForm<any>();
  const [doughnutReportData, setDoughtnutReportData] = useState();
  const [barReportData, setBarReportData] = useState();
  const [barReportLabel, setBarReportLabel] = useState();

  filterParams.type = filterParams.type.toUpperCase();
  filterParams.access = filterParams.access.toUpperCase();

  const teamFilter = () => {
    if ((user.is_manager && userRole === 4) || userRole === 1) {
      return ['Team', 'Me'];
    } else if (userRole === 4 && !user?.is_manager) {
      return ['Me'];
    } else {
      return ['Me', 'Team'];
    }
  };

  const projectListtQuery = useQuery([`getAllProjects`], async () => {
    const response = await projectAPI.list({ limit: 1000 });
    return response?.data?.data;
  });

  const { data: dashboardReport, isLoading: isDashboardReportLoading } =
    useQuery(
      ['dashboardReport', filterParams],
      async () => {
        const body = {
          type: filterParams.type,
          access: filterParams.access,
          date_from: filterParams.date_from,
          date_to: filterParams.date_to,
          location,
          timer_status: filterParams.timer_status,
          project_id: filterParams.project_id.map((p) => p.id),
        };
        const response = await reportAPI.dashboardReport(body);
        let data: any = [];
        response?.data?.data?.project_and_total_time.map((res: any) => {
          data.push({
            labels: res?.project_name,
          });
        });

        setDoughnutReport(
          filterParams.type === 'PROJECT'
            ? response?.data?.data?.project_and_total_time
            : response?.data?.data?.billable_and_total_time
        );
        if (filterParams.type !== 'PROJECT') {
          data = [
            {
              labels: 'Billable',
            },
            {
              labels: 'Non Billable',
            },
          ];
        }
        setGroupBarReport(response?.data?.data?.date_and_total_time, data);
        dispatch(setDashboardFilter(filterParams));
        return response?.data;
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { data: mostTrackedItem, isLoading: isMostTrackedItemLoading } =
    useQuery(
      ['mostTrackedItem', filterParams],
      async () => {
        const { type, access, project_id, ...filters } = filterParams;

        const response = await reportAPI.mostTrackedItem(filters);

        return response?.data?.data;
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const setDoughnutReport = (reportData: any) => {
    let data: any = [];
    if (filterParams.type === 'PROJECT') {
      reportData.map((res: any) => {
        data.push({
          labels: res?.project_name,
          percentage: res?.percentage.replace(/%/g, ''),
          color: res?.color,
          billable_duration: res?.billable_duration,
          duration: res?.duration,
        });
      });
    } else {
      data = [
        {
          labels: 'Billable',
          percentage: reportData?.billable?.percentage.replace(/%/g, ''),
          color: reportData?.billable?.color,
          duration: reportData?.billable?.duration,
        },
        {
          labels: 'Non Billable',
          percentage: reportData?.non_billable?.percentage.replace(/%/g, ''),
          color: reportData?.non_billable?.color,
          duration: reportData?.non_billable?.duration,
        },
      ];
    }
    setDoughtnutReportData(data);
  };

  const setGroupBarReport = (DateAndTotalTime: any, labelData: any) => {
    let labels: any = [];
    const len = DateAndTotalTime.length;
    if (len > 0) {
      let data = DateAndTotalTime;
      let totalReport: any = [];
      for (let i = 0; i < len; i++) {
        let key = Object.keys(data[i])[0];
        labels.push(dayjs(key).format('ddd, MMM DD'));
        if (filterParams.type === 'PROJECT' && labelData.length > 0) {
          labelData.map((data: any) => {
            const filterData = DateAndTotalTime[i][key].filter((x: any) => {
              return x.label === data.labels;
            });
            if (filterData.length > 0) {
              if (data.labels === filterData[0].label) {
                totalReport.push({
                  label: filterData[0].label,
                  data: filterData[0].duration,
                  backgroundColor: filterData[0].color,
                  stack: 'Stack 0',
                });
              } else {
                totalReport.push({
                  label: data.label,
                  data: '0',
                  backgroundColor: getRandomColor(),
                  stack: 'Stack 0',
                });
              }
            }
          });
        } else {
          if (DateAndTotalTime[i][key].length > 0)
            DateAndTotalTime[i][key].map((res: any) => {
              totalReport.push(
                {
                  label: 'Billable',
                  data: res.billable_duration,
                  backgroundColor: res.color,
                  stack: 'Stack 0',
                },
                {
                  label: 'Non Billable',
                  data: res.duration,
                  backgroundColor: '#AED581',
                  stack: 'Stack 0',
                }
              );
            });
        }
      }
      var results = totalReport.reduce(function (res: any, value: any) {
        if (!res[value.label]) {
          res[value.label] = {
            label: value.label,
            data: [],
            backgroundColor: value.backgroundColor,
            stack: 'Stack 0',
          };
        }
        res[value.label].data.push(value.data);
        return res;
      }, {});
      const barData = labelData.map((data: any) => {
        return results[data.labels];
      });
      setBarReportData(barData);
    }
    setBarReportLabel(labels);
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(e.target.value);
  };

  const handleTimerStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimerStatus(e.target.value);
  };

  const handleAccessChange = (e: any) => {
    setAccess(e.target.value);
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return 'Select date Range';
    if (!eDate) return `${DateFormatYMD(sDate)} - Select End Date`;
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const filterList = async () => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      type: type.toUpperCase(),
      access: access.toUpperCase(),
      date_from: getDateOnly(startDate),
      date_to: getDateOnly(endDate),
      project_id: selectedProject,
    }));
    // dispatch dashboard filters
    dispatch(setDashboardDatePreset(activePreset));
  };

  const handleReset = () => {
    setSelectedProject([]);
    setStartDate(new Date());
    setEndDate(new Date());
    setActivePreset(0);
    setLocation('all');
    setTimerStatus('all');
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Dashboard</h2>

      <div className="mt-5">
        <Card>
          <Card.Body>
            <form onSubmit={methods.handleSubmit(filterList)}>
              <div className="row">
                {/* <div className="col-md-6 mt-2 col-xl-2">
                  <select
                    className="form-control form-select"
                    {...methods.register('type')}
                    onChange={handleTypeChange}
                    defaultValue={type}
                    placeholder="Select">
                    <option value="project">Project</option>
                    <option value="billability">Billability</option>
                  </select>
                </div> */}
                <HideControl
                  isManager={user?.is_manager}
                  renderNoAccess={(data: any) => {
                    return data ? data : '';
                  }}>
                  <div
                    className="col-md-2 mt-2 col-xl-1"
                    style={{ width: '140px' }}>
                    <select
                      className="form-control form-select"
                      {...methods.register('location')}
                      onChange={handleLocationChange}
                      value={location}
                      placeholder="Select">
                      <option value="all">Location</option>
                      <option value="all">All</option>
                      <option value="nepal">Nepal</option>
                      <option value="peru">Peru</option>
                      <option value="usa">USA</option>
                    </select>
                  </div>
                </HideControl>
                <HideControl
                  isManager={user?.is_manager}
                  renderNoAccess={(data: any) => {
                    return data ? data : '';
                  }}>
                  <div className="col-md-2 mt-2 col-xl-2">
                    <select
                      className="form-control form-select"
                      {...methods.register('timer_status')}
                      onChange={handleTimerStatusChange}
                      value={timerStatus}
                      placeholder="Select">
                      <option value="all">Timer</option>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </HideControl>
                <div className="col-md-2 col-xl-2  mt-2">
                  <MultiSelectAll
                    options={projectListtQuery?.data}
                    placeholder={'Select Project'}
                    arrayValue={selectedProject}
                    setArrayValue={setSelectedProject}
                  />
                </div>
                <div
                  className="col-md-2 mt-2 col-xl-1"
                  style={{ width: '130px' }}>
                  <select
                    className="form-control form-select"
                    {...methods.register('access')}
                    onChange={handleAccessChange}
                    defaultValue={access}
                    placeholder="Select"
                    disabled={userRole === 4 && !user?.is_manager}>
                    {teamFilter().map((t, idx) => (
                      <option key={idx} value={t}>
                        {t}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 mt-2 col-xl-3">
                  <ReactDatePicker
                    className="date-input-field"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      setActivePreset(-1);
                      handleDateChange(date as [Date, Date]);
                    }}
                    monthsShown={2}
                    selected={startDate}
                    startDate={startDate}
                    maxDate={new Date()}
                    endDate={endDate}
                    selectsRange
                    customInput={
                      <div
                        style={
                          !endDate
                            ? {
                                border: '1px solid red',
                                height: '40px',
                                borderRadius: '0.25rem',
                                padding: '0.375rem 2.25rem 0.375rem 0.75rem',
                              }
                            : {
                                border: '1px solid #ced4da',
                                height: '40px',
                                borderRadius: '0.25rem',
                                padding: '0.375rem 2.25rem 0.375rem 0.75rem',
                              }
                        }>
                        {getFormattedRangeDate(startDate, endDate)}
                      </div>
                    }
                    shouldCloseOnSelect={false}
                    autoComplete="off">
                    <div className="row w-40 days-wrapper">
                      {presets.map(({ text, start, end }, index) => {
                        return (
                          <button
                            className={
                              // active class will be added by checking the index of the preset
                              'btn btn-primary ' +
                              (activePreset === index ? 'active' : '')
                            }
                            style={{ height: '32px' }}
                            key={text}
                            type="button"
                            onClick={() => {
                              // after a preset is selected, its index will be set as activePreset,
                              // and the start and end date will change.
                              setActivePreset(index);
                              handleDateChange([start.toDate(), end.toDate()]);
                            }}>
                            {text}
                          </button>
                        );
                      })}
                    </div>
                  </ReactDatePicker>
                </div>
                <div
                  className="col-md-2 mt-2 col-xl-1 col-sm-2"
                  style={{ width: '90px' }}>
                  <Button
                    disabled={!endDate}
                    style={{ width: '100%' }}
                    variant="primary"
                    type="submit">
                    Go
                  </Button>
                </div>
                <div className="col-md-2 mt-2 col-xl-1 col-sm-2">
                  <Button
                    type="submit"
                    style={{ width: '70px' }}
                    onClick={handleReset}
                    className="button danger-button">
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>

      {/** removing this as a comment but keeping it as we may need it in the future */}

      {!isDashboardReportLoading && (
        <>
          <div className="row  dashboard-timer-wrapper">
            {!ADMIN_ROLES.includes(user.id) && (
              <div
                className={
                  filterParams.access === 'ME' ? 'col-md-8 col-sm-12' : 'col-12'
                }>
                <div className="mt-3 dashboard-timer-wrap">
                  <div
                    className="col p-2 text-center border-right-0 text-white"
                    style={{ backgroundColor: '#326BA8', border: '1px solid' }}>
                    <span>Total Time</span>
                    <h2>{getTime(dashboardReport?.data?.total_time)}</h2>
                  </div>
                  {filterParams.type !== 'PROJECT' && (
                    <div
                      className="col p-2 text-center text-white"
                      style={{
                        backgroundColor: '#326BA8',
                        border: '1px solid',
                      }}>
                      <span>Billable Time</span>
                      <h2>{getTime(dashboardReport?.data?.total_billable)}</h2>
                    </div>
                  )}
                </div>
                <section id="group-bar" className="bg-white">
                  <GroupBarComponent
                    labelData={barReportLabel}
                    isLegendDisplay={true}
                    reportData={barReportData}
                  />
                </section>

                {dashboardReport?.data?.project_and_total_time.length > 0 && (
                  <section id="doughnet">
                    <hr />
                    <div className="flex-wrap d-flex justify-content-between align-items-center p-5 bg-white">
                      <div className="pie-width-wrap col-md-6 col-sm-12">
                        {
                          <DoughnutComponent
                            reportData={doughnutReportData}
                            reportType={filterParams.type}
                          />
                        }
                      </div>
                      {dashboardReport?.data?.project_and_total_time?.length >
                        0 && (
                        <div
                          className="table-responsive col-md-6 col-sm-12"
                          style={{ height: '500px' }}>
                          <table className="dashboard-report-table">
                            <tbody>
                              {dashboardReport?.data?.project_and_total_time?.map(
                                (res: any) => (
                                  <tr key={res?.project_id}>
                                    <td>{res?.project_name}</td>
                                    <td>{getTime(res?.duration)}</td>
                                    <td width="40%">
                                      <progress
                                        value={parseFloat(
                                          res?.percentage.replace(/%/g, '')
                                        )}
                                        max="100"
                                        style={{
                                          width: '100%',
                                          border: '1px solid #ccc',
                                          height: '20px',
                                        }}
                                      />
                                    </td>
                                    <td>{res?.percentage}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </div>
            )}
            {filterParams.access === 'ME' && (
              <div className="col-md-4 col-sm-12">
                <div className="row mt-3 px-2">
                  <div
                    className="col p-2 text-center border-right-0 text-white"
                    style={{ backgroundColor: '#326BA8', border: '1px solid' }}>
                    <span>Most tracked activities</span>
                  </div>
                </div>
                <section id="most-tracked-items" className="bg-white">
                  {mostTrackedItem &&
                    mostTrackedItem.length > 0 &&
                    mostTrackedItem.map((item: any) => (
                      <div key={item.id} className="row items-traker">
                        <div
                          className="col-8"
                          style={{ wordWrap: 'break-word' }}>
                          {item?.description}
                          <p className="ms-2">
                            <span
                              className="dot"
                              style={{
                                background: item?.TimeSheetProject?.color_code,
                              }}></span>
                            {item?.TimeSheetProject?.name}
                          </p>
                        </div>
                        <div className="col-4 text-right activities-timer">
                          {getTime(item?.duration)}
                        </div>
                      </div>
                    ))}
                </section>
              </div>
            )}
          </div>
          <HideControl
            isManager={user?.is_manager}
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            {filterParams.access !== 'ME' && (
              <section id="team-activities" className="mt-4">
                <div className="bg-white" style={{ margin: '0 12px' }}>
                  <TeamActivities
                    startDate={filterParams.date_from}
                    endDate={filterParams.date_to}
                    type={filterParams.type}
                    projectId={filterParams.project_id}
                    location={location}
                    timerStatus={timerStatus}
                  />
                </div>
              </section>
            )}
          </HideControl>
        </>
      )}
      {/*{isDashboardReportLoading && <CenterSpinner />}*/}
    </>
  );
};
export default SplashScreen;
