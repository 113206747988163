import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
interface Props {
  projectData: any;
}

const TeamMemberProjectListItem: React.FC<Props> = (props) => {
  const { projectData } = props;

  return (
    <>
      <tr key={projectData.id}>
        <td
          style={
            projectData?.status === false
              ? { textDecoration: 'line-through' }
              : { textDecoration: 'none' }
          }>
          {projectData?.name}
        </td>
        <td>{projectData?.client?.name || '-'}</td>
        <td>{projectData?.privacy || '-'}</td>

        <td>
          {projectData?.status
            ? projectData.status.charAt(0).toUpperCase() +
              projectData.status.slice(1)
            : '-'}
        </td>
      </tr>
    </>
  );
};

export default TeamMemberProjectListItem;
