import { Redirect, Route } from "react-router-dom";

import { Component } from "react";
import React from "react";
import routes from "constants/routes";
import { withAuthState } from "components/hoc/auth";

interface IProps {
  isLoggedIn?: boolean;
  [key: string]: any;
}

/**
 * Component to authenticate private routes.
 */
class AuthenticateRoute extends Component<IProps, any> {
  render() {
    let { isLoggedIn } = this.props;

    return isLoggedIn ? (
      <Route {...this.props} />
    ) : (
      <Redirect to={routes.auth.login} />
    );
  }
}

export default withAuthState(AuthenticateRoute);
