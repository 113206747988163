const routes = {
  dashboard: '/',
  reports: '/reports',
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
  },
  teams: {
    list: '/teams',
    groups: {
      create: '/teams/groups/create',
      edit: '/teams/groups/edit/:id',
      assign_member: '/teams/groups/:id/member',
    },
    members: {
      create: '/teams/members/create',
      edit: '/teams/members/edit/:id',
      view: '/teams/members/view/:id',
    },
  },
  projects: {
    list: '/projects',
    view: '/projects/view/:id',
    create: '/projects/create',
    assign_member: '/projects/:id/member',
    edit: '/projects/edit/:id',
  },
  profile: {
    myProfile: '/my-profile',
  },
  tracker: {
    list: '/tracker',
  },
  tags: {
    list: '/tags',
    create: '/tags/create',
    edit: '/tags/edit/:id',
  },
  clients: {
    list: '/clients',
    create: '/clients/create',
    edit: '/clients/edit/:id',
  },
};

export default routes;
