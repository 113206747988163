import Resource from 'api/resource';
import http from 'utils/http';

class ProjectResource extends Resource {
  constructor() {
    super('projects');
  }

  /**
   * Assign Member to Project
   */
  assignMember(projectId: number, data: any) {
    return http({
      url: '/projects/' + projectId + '/members',
      method: 'post',
      data: data,
    });
  }

  // unassign Project member
  unassignProjectMember(projectId: number, memberId: number) {
    return http({
      url: '/projects/' + projectId + '/members/' + memberId,
      method: 'delete',
    });
  }
  /**
   * Check if current user is project manager
   */
  isCurrentUserProjectManager(projectId: number) {
    return http({
      url: '/projects/' + projectId + '/manager',
      method: 'get',
    });
  }
  /**
   * grant project manager access to user
   */
  grantProjectManager(projectId: number, memberId: number) {
    return http({
      url: '/projects/' + projectId + '/members/' + memberId + '/manager',
      method: 'post',
    });
  }
  /**
   * Revoke project manager access to user
   */
  revokeProjectManager(projectId: number, memberId: number) {
    return http({
      url: '/projects/' + projectId + '/members/' + memberId + '/manager',
      method: 'delete',
    });
  }
}

export { ProjectResource as default };
