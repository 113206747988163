import UserResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TeamMemberProjectListItem from 'components/teams/TeamMemberProjectListItem';
import routes from 'constants/routes';
import React from 'react';
import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Image,
  Row,
  Table,
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import history from 'utils/history';
import TrackifyToast from 'utils/toast';

const ViewTeamMember: React.FC = () => {
  const { id }: any = useParams();
  const memberApi = new UserResource();

  const memberQuery = useQuery<any>(
    [`member${id}`, id],
    () => memberApi.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const memberProjectQuery = useQuery<any>(
    [`member-project${id}`, id],
    () => memberApi.userProjects(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const resendEmail = useMutation(
    (data: any) => memberApi.resendEmailVerification(data),
    {
      onSuccess: () => {
        TrackifyToast.success('User Reinvited');
      },
      onError: () => {
        TrackifyToast.error('Cannot reinvite user');
      },
    }
  );

  if (memberQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (memberQuery.isError) {
    history.push(routes.teams.list);
  }

  const getGroups = () => {
    if (memberQuery?.data?.groups?.length > 0) {
      const groups = memberQuery?.data?.groups?.map((res: any) => {
        return res?.group_detail?.name;
      });
      return <Badge bg="info">{groups.join(', ')}</Badge>;
    }
    return '-';
  };

  const handleResendEmailVerification = () => {
    resendEmail.mutate(id);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.teams.list}>Team</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.teams.list}?name=members`}>
          Team List
        </Breadcrumb.Item>
        <Breadcrumb.Item>{memberQuery?.data?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h2>Member Details</h2>
        <div className="d-flex justify-content-end">
          {memberQuery?.data?.status === 'invited' && (
            <Button
              style={{ marginRight: '10px' }}
              className="button primary-button"
              onClick={() => handleResendEmailVerification()}>
              Resend Email Verification
            </Button>
          )}
          <Button
            className="button primary-button"
            onClick={() =>
              history.push(
                routes.teams.members.edit.replace(
                  ':id',
                  memberQuery?.data?.id.toString()
                )
              )
            }>
            Edit
          </Button>
        </div>
      </div>
      <Row className="mt-5">
        <Col md={4}>
          <h5>Name</h5>
          <p> {memberQuery?.data?.name}</p>
        </Col>
        <Col md={4}>
          <h5>Email</h5>
          <p> {memberQuery?.data?.email}</p>
        </Col>
        <Col md={4}>
          <h5>Phone</h5>
          <p> {memberQuery?.data?.phone}</p>
        </Col>
        <Col md={4}>
          <h5>Status</h5>
          <p> {memberQuery?.data?.status}</p>
        </Col>
        <Col md={4}>
          <h5>Role</h5>
          <p>
            {' '}
            {<Badge bg="info">{memberQuery?.data?.role?.name}</Badge> ||
              '-'}{' '}
          </p>
        </Col>
        <Col md={4}>
          <h5>Groups</h5>
          <p> {getGroups()}</p>
        </Col>
        {memberQuery?.data?.image_url && (
          <Col md={4}>
            <Image src={memberQuery?.data?.image_url} width="100px" />
          </Col>
        )}
      </Row>
      <Row className="mt-5">
        <h3>Projects</h3>
        <Table hover className="mt-3">
          <thead>
            <tr>
              <th>PROJECTS</th>
              <th>CLIENT</th>
              <th>PRIVACY</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {!memberProjectQuery.isLoading &&
              memberProjectQuery?.data?.map(
                (projectList: any, index: number) => (
                  <TeamMemberProjectListItem
                    key={projectList.id}
                    projectData={projectList}
                  />
                )
              )}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default ViewTeamMember;
