import ReportResource from 'api/reports';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { Button, Card, Image, Modal } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getDuration, getTime, truncateString } from 'utils';
import TimeSheetResource from '../../api/timesheet';
import { IProject } from '../../pages/dashboard/SplashScreen/SplashScreen';
import TrackifyToast from '../../utils/toast';

dayjs.extend(relativeTime);

interface Props {
  startDate: string;
  endDate: string;
  type: string;
  projectId: IProject[];
  location: string;
  timerStatus: string;
}

const TeamActivities: React.FunctionComponent<Props> = (props) => {
  const { startDate, endDate, type, projectId, location, timerStatus } = props;
  let reportAPI = new ReportResource();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const queryClient = useQueryClient();

  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();

  const timeSheetAPI = new TimeSheetResource();

  const onTimerStop = (userId: number) => {
    setSelectedId(userId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const stopTimer = useMutation(
    'stopTimer',
    (data: { id: number }) => timeSheetAPI.stopTimeSheet(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teamActivitiesReport');
        handleClose();
        TrackifyToast.success('Timer Stopped');
      },
      onError: () => {
        TrackifyToast.error('Error stopping timer');
      },
    }
  );

  const onConfirm = () => {
    stopTimer.mutate({ id: selectedId as number });
  };

  const {
    data: teamActivitiesReport,
    isLoading: isTeamActivitiesReportLoading,
  } = useQuery(
    ['teamActivitiesReport', props],
    async () => {
      const params = {
        date_from: startDate,
        date_to: endDate,
        type,
        location,
        timer_status: timerStatus,
        project_id: projectId?.map((p) => p.id),
      };
      const response = await reportAPI.teamActivitiesReport(params);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const getRealTime = (status: string, duration: string, timeFrom: string) => {
    if (status?.toLowerCase() === 'active') {
      return getDuration(new Date(), timeFrom);
    } else return getTime(duration);
  };

  if (!isTeamActivitiesReportLoading && !teamActivitiesReport?.data) {
    return (
      <div
        style={{
          textAlign: 'center',
          fontSize: '20px',
        }}>
        Nothing to Show
      </div>
    );
  }

  return (
    <>
      {!isTeamActivitiesReportLoading && (
        <>
          <div className="row">
            <Card.Header className="dashboard-team-activites-card-header">
              Team activities
            </Card.Header>
          </div>
          <div className="row">
            <div className="dashboard-team-activites-table-wrapper table-responsive">
              <table className="dashboard-team-activites-table">
                <thead>
                  <tr>
                    <th colSpan={2}>TEAM MEMBER</th>
                    <th colSpan={3}>LAST ACTIVITY</th>
                    <th>
                      {`TOTAL TRACKED`}
                      <p>{`${startDate} -- ${endDate}`}</p>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {teamActivitiesReport &&
                    teamActivitiesReport?.data?.map(
                      (activity: any, index: number) => (
                        <tr key={index}>
                          <td width="5%">
                            {activity?.image_url !== null && (
                              <Image
                                className="rounded-image"
                                roundedCircle
                                src={`${baseUrl}/${activity?.image_url}  `}
                              />
                            )}
                            {activity.image_url === null && (
                              <BiUser className="rounded-image" />
                            )}
                          </td>
                          <td width="15%">{activity?.user_name}</td>

                          <td width="25%">
                            <div className="activity-description">
                              <p title={activity?.description}>
                                {truncateString(activity?.description)}
                              </p>
                              <p>
                                <span
                                  className="dot"
                                  style={{
                                    background: activity?.project_color,
                                  }}></span>{' '}
                                {activity?.project_name}
                                {activity?.client_name ? (
                                  <span className="text-secondary">
                                    - {activity?.client_name}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </p>
                            </div>
                          </td>
                          <td width="15%">
                            {getRealTime(
                              activity?.status,
                              activity?.duration,
                              activity?.time_from
                            )}
                          </td>
                          <td width="10%">
                            {activity?.status.toLowerCase() === 'inactive' ? (
                              dayjs(activity?.last_active.toString()).fromNow()
                            ) : (
                              <p>
                                In Progress{' '}
                                <span
                                  className="dot"
                                  style={{
                                    background: 'red',
                                  }}></span>
                              </p>
                            )}
                          </td>
                          <td width="20%">
                            <div className="activity-tracker">
                              {getTime(activity?.duration_sum)}
                            </div>
                          </td>
                          {activity?.status.toLowerCase() === 'active' ? (
                            <td width="20%">
                              <Button
                                className="btn btn-danger"
                                onClick={() => onTimerStop(activity?.user_id)}>
                                STOP
                              </Button>
                            </td>
                          ) : (
                            <td width="20%"></td>
                          )}
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Stop Running Timer?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This action will stop the running timer and set the end time to
                current date and time. Continue?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};
export default TeamActivities;
