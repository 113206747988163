export const ChangePasswordValidation = (values: any) => {
  const errors = {} as any;

  if (!values.old_password) {
    errors.old_password = "Please enter old password";
  }
  if (!values.password) {
    errors.password = "Please enter new password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Please enter confirm password";
  }
  if (values.confirm_password !== values.password) {
    errors.confirm_password = "Password does not match.";
  }
  return errors;
};
