import GroupResource from 'api/groups';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { BiEdit, BiUserCheck } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import { truncateString } from 'utils';
import TrackifyToast from 'utils/toast';

interface Props {
  teamGroupData: any;
}

const TeamGroupListItem: React.FC<Props> = (props) => {
  const { teamGroupData } = props;
  const groupAPI = new GroupResource();
  const [show, setShow] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const queryClient = useQueryClient();

  const getMembers = () => {
    if (teamGroupData?.members?.length > 0) {
      const members = teamGroupData?.members?.map((res: any) => {
        return res?.user?.name;
      });
      return (
        <Badge bg="info" title={members.join(', ')}>
          {truncateString(members.join(', '), 150)}
        </Badge>
      );
    }
    return '-';
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteTeamGroup = useMutation(
    (data: any) => groupAPI.update(teamGroupData?.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teamGroupList');
        handleClose();
        TrackifyToast.success('Group updated');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.message);
      },
    }
  );

  const onDeleteConfirm = () => {
    const status = teamGroupData?.status === 'active' ? 'inactive' : 'active';
    deleteTeamGroup.mutate({ status: status });
  };

  return (
    <>
      <tr key={teamGroupData?.id}>
        <td
          style={
            teamGroupData?.status === 'active'
              ? { textDecoration: 'none' }
              : { textDecoration: 'line-through' }
          }>
          {teamGroupData?.name}
        </td>
        <td>{getMembers()}</td>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <td>
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={handleShow}
                checked={teamGroupData?.status === 'active' ? true : false}
              />
            </Form>
          </td>
          <td className="action-buttons">
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
              to={routes.teams.groups.edit.replace(
                ':id',
                teamGroupData.id.toString()
              )}>
              <BiEdit />
            </RouterLink>{' '}
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Assign Member"
              to={routes.teams.groups.assign_member.replace(
                ':id',
                teamGroupData.id.toString()
              )}>
              <BiUserCheck />
            </RouterLink>
          </td>
        </HideControl>
      </tr>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {teamGroupData?.status === 'active' ? 'Inactive' : 'Active'} Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteErrorMsg && (
            <p className="alert-danger p-2">{deleteErrorMsg}</p>
          )}
          Are you sure you want to{' '}
          {teamGroupData?.status === 'active' ? 'Inactive' : 'Active'} it ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDeleteConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeamGroupListItem;
