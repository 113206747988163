import ProjectResource from 'api/projects';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ProjectMemberListItem from 'components/projects/ProjectMemberListItem';
import { ProjectTypeEnum } from 'constants/common';
import routes from 'constants/routes';
import React from 'react';
import { Badge, Breadcrumb, Button, Col, Row, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { filterProjectMemberByProject } from 'utils';
import history from 'utils/history';

const ViewProject: React.FC = () => {
  const projectTypeEnum: any = ProjectTypeEnum;
  const { id }: any = useParams();
  const projectApi = new ProjectResource();

  const { userDetail } = useSelector(
    (state: any) => ({
      userDetail: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const projectQuery = useQuery<any>(
    [`project-${id}`, id],
    () => projectApi.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const projectManagerQuery = useQuery<any>(
    [`project-${id}-manager`, id],
    () =>
      projectApi.isCurrentUserProjectManager(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (projectQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (projectQuery.isError) {
    return (
      <h3 className="text-align-center">
        You do not have access to this project. Please contact administrator.
      </h3>
    );
  }

  const getGroups = () => {
    if (projectQuery?.data?.groups?.length > 0) {
      const groups = projectQuery?.data?.groups?.map((res: any) => {
        return res?.group_detail?.name;
      });
      return <Badge bg="info">{groups.join(', ')}</Badge>;
    }
    return '-';
  };

  const projectData = filterProjectMemberByProject(
    projectQuery?.data,
    userDetail.id
  );

  const isManager =
    projectData.members && projectData?.members.length > 0
      ? projectData.members[0].is_manager
      : false;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={routes.projects.list}>Project</Breadcrumb.Item>
        <Breadcrumb.Item href={`${routes.projects.list}?name=members`}>
          Project List
        </Breadcrumb.Item>
        <Breadcrumb.Item>{projectQuery?.data?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h2>Project Details</h2>
        <div className="d-flex align-items-end flex-column">
          <HideControl
            renderNoAccess={(data: any) => {
              return data ? data : '';
            }}>
            <Button
              style={{ float: 'right' }}
              className="button primary-button"
              onClick={() =>
                history.push(
                  routes.projects.edit.replace(
                    ':id',
                    projectQuery?.data?.id.toString()
                  )
                )
              }>
              Edit
            </Button>
          </HideControl>
        </div>
      </div>
      <Row className="mt-5 project-details-wrap">
        <Col md={3}>
          <h5>Name</h5>
          <p> {projectQuery?.data?.name}</p>
        </Col>
        <Col md={3}>
          <h5>Client</h5>
          <p>
            {' '}
            {projectQuery?.data?.client?.name
              ? projectQuery?.data?.client?.name
              : '-'}
          </p>
        </Col>
        <Col md={3}>
          <h5>Privacy</h5>
          <p> {projectQuery?.data?.privacy}</p>
        </Col>
        <Col md={3}>
          <h5>Is Billable</h5>
          <p>
            {' '}
            {projectQuery?.data?.is_billable && !!projectQuery.data.is_billable
              ? 'Yes'
              : 'No'}
          </p>
        </Col>
        <Col md={3}>
          <h5>Clickup Space Id</h5>
          <p>{projectQuery?.data?.clickup_id || '-'} </p>
        </Col>
        <Col md={3}>
          <h5>Type</h5>
          <p>{projectTypeEnum[projectData?.type] || '-'}</p>
        </Col>
      </Row>
      <>
        <Row className="mt-5">
          <Col md={12}>
            <div className="d-flex justify-content-between">
              <h2>Members</h2>
              <div className="d-flex align-items-end flex-column">
                <HideControl
                  isManager={isManager}
                  renderNoAccess={(data: any) => {
                    return data ? data : '';
                  }}>
                  <Button
                    style={{ float: 'right' }}
                    className="button primary-button"
                    onClick={() =>
                      history.push(
                        routes.projects.assign_member.replace(
                          ':id',
                          projectData?.id.toString()
                        )
                      )
                    }>
                    Add
                  </Button>
                </HideControl>
              </div>
            </div>
          </Col>
        </Row>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>TECHSTACK</th>
              <th>ASSIGNMENT TYPE</th>
              <th>PHONE</th>
              <HideControl
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <th>IS MANAGER?</th>
                <th>ACTION</th>
              </HideControl>
            </tr>
          </thead>
          <tbody>
            {projectQuery?.data?.members.length > 0 &&
              projectQuery?.data?.members.map((member: any) => (
                <ProjectMemberListItem
                  key={member.id}
                  membersData={member}
                  isManager={userDetail?.is_manager}
                  projectId={projectQuery?.data?.id}
                />
              ))}
            {projectQuery?.data?.members.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: 'center' }}>
                  No members assigned
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    </>
  );
};

export default ViewProject;
