import { NavBar, SideBar } from 'layouts';
import SideBarMobile from 'layouts/SideBar/SideBarMobile';
import React, { useState } from 'react';
import PrivateRouter from 'router/PrivateRouter';
const Dashboard: React.FC = () => {
  const [toggle, setToggle] = useState(
    sessionStorage.getItem('toggle') === 'true' ? true : false
  );

  const handleSideBarToggle = () => {
    const toggleBar = sessionStorage.getItem('toggle');
    sessionStorage.setItem('toggle', toggleBar === 'true' ? 'false' : 'true');
    setToggle(toggleBar === 'true' ? false : true);
  };

  return (
    <>
      <NavBar handleSideBarToggle={handleSideBarToggle} toggle={toggle} />
      {/* <NavBar setToggle={setToggle} toggle={toggle} />  */}
      <div className="main---wrapper container-fluid">
        <div className="flex-nowrap main-box main-content--wrapper">
          <div
            className={
              toggle
                ? 'sidebar-mini col-xl-1 col-md-1 col-sm-1'
                : 'sidebar-main  col-xl-2 col-md-3 col-sm-3'
            }>
            <SideBar toggle={toggle} />
          </div>
          <div
            className={
              toggle ? 'sidebar-main-mobile active' : 'sidebar-main-mobile'
            }
            onClick={handleSideBarToggle}>
            <SideBarMobile toggle={toggle} />
          </div>
          <div
            className={
              toggle
                ? 'p-4 main-container-big  col-xl-11 mt-5 col-md-11 col-sm-11'
                : 'p-4 main-container mt-5 col-sm-9 col-md-9 col-xl-10'
            }>
            <PrivateRouter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
