import { toast, ToastOptions } from 'react-toastify';
import { CustomToastInterface } from './interfaces/toastInterface';

toast.configure();

class CustomToast implements CustomToastInterface {
  defaultOptions: ToastOptions;
  constructor() {
    this.defaultOptions = {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
  }

  success(message: string, options?: ToastOptions) {
    toast.success(message, { ...this.defaultOptions, ...options });
  }
  error(message: string, options?: ToastOptions) {
    toast.error(message, { ...this.defaultOptions, ...options });
  }
  info(message: string, options?: ToastOptions) {
    toast.info(message, { ...this.defaultOptions, ...options });
  }
}

const TrackifyToast = new CustomToast();

export default TrackifyToast;
