import RoleResource from 'api/roles';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useQuery } from 'react-query';

interface Props {
  teamMemberData?: any;
}

const TeamMemberForm: React.FC<Props> = (props) => {
  const { teamMemberData } = props;
  const roleAPI = new RoleResource();

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<any>();

  const { data: roleList, isLoading: isRoleLoading } = useQuery(
    ['roleList'],
    async () => {
      const response = await roleAPI.list();
      return response?.data?.data;
    }
  );

  const handleValidate = (value: any) => {
    try {
      if (value !== '' || value.length < 11) {
        const isValid = isValidPhoneNumber(value);
        return isValid;
      } else if (value !== '' || value.length < 11) {
        const isValid = isValidPhoneNumber(value);
        return isValid;
      } else {
        return errors;
      }
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              defaultValue={teamMemberData?.name}
              placeholder="Name"
              maxLength={64}
              {...register('name', {
                required: 'Please Enter Member Name.',
                minLength: {
                  value: 3,
                  message: 'Must be atleast 3 characters',
                },
              })}
              className={errors.name ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name && errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Email
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="email"
              defaultValue={teamMemberData?.email}
              placeholder="Email"
              {...register('email', {
                required: 'Please Enter Valid Email.',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Please Enter Valid Email.',
                },
              })}
              className={errors.email ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.email && errors?.email?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Phone
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as={PhoneInput}
              maxLength={20}
              defaultCountry="NP"
              countries={['US', 'NP', 'PE']}
              defaultValue={teamMemberData?.phone}
              className={errors.phone ? 'is-invalid d-flex p-0 ' : 'd-flex p-0'}
              {...register('phone', {
                required: 'Please Enter Valid phone number.',
                minLength: {
                  value: 11,
                  message: 'Please Enter Valid phone number.',
                },
                validate: { handleValidate },
              })}
              value={teamMemberData?.phone}
              onChange={handleValidate}
              international
              addInternationalOption={false}
              placeholder="Phone Number"
              // style={{ paddingLeft: '5px !important' }}
            />

            <Form.Control.Feedback type="invalid">
              {errors?.phone && errors?.phone?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Role
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              rules={{
                required: { value: true, message: 'Please Select Member Role' },
              }}
              defaultValue={
                teamMemberData?.role_id ? teamMemberData.role_id : 'selected'
              }
              name="role_id"
              render={({ field }) => (
                <select
                  className={
                    errors.email
                      ? 'is-invalid form-control form-select'
                      : ' form-control form-select'
                  }
                  style={{ maxWidth: '100%' }}
                  placeholder="Select"
                  id="role_id"
                  {...field}>
                  <option disabled value="selected">
                    Select
                  </option>
                  {roleList &&
                    roleList.map((item: any) => {
                      return (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              )}
            />
            {errors?.role_id && (
              <small style={{ color: 'red' }}>{errors?.role_id.message}</small>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextType">
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="10">
            <Controller
              control={control}
              name="user_type"
              render={({ field }) => (
                <select
                  className="form-control form-select"
                  placeholder="Select"
                  id="user_type"
                  {...field}
                  defaultValue={teamMemberData?.user_type}>
                  <option value="">Select</option>
                  <option value="engineer">Engineer</option>
                  <option value="project_manager">Project Manager</option>
                  <option value="product_owner">Product Owner</option>
                  <option value="sales">Sales</option>
                  <option value="design">Design</option>
                  <option value="operation">Operation</option>
                  <option value="account">Account</option>
                  <option value="partnership">Operation</option>
                </select>
              )}
            />
          </Col>
        </Form.Group>
        {teamMemberData?.name && (
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Status
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <select
                    className="form-control"
                    placeholder="Select"
                    id="status"
                    {...field}
                    defaultValue={teamMemberData?.status}>
                    <option>Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="invited">Invited</option>
                  </select>
                )}
              />
            </Col>
          </Form.Group>
        )}
      </form>
    </>
  );
};

export default TeamMemberForm;
