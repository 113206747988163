import { forgotPassword } from 'api/auth';
import routes from 'constants/routes';
import { useFormik } from 'formik';
import LoginFooterLayout from 'layouts/LoginLayout/LoginFooterLayout';
import LoginHeaderLayout from 'layouts/LoginLayout/LoginHeaderLayout';
import React, { useState } from 'react';
import { Button, Container, Form, Stack } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import TrackifyToast from 'utils/toast';
import { ForgotPasswordValidation } from './AuthValidation';

const ForgotPassword: React.FC = () => {
  const [errMsg, setErrMsg] = useState<string>('');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: ForgotPasswordValidation,
    onSubmit: (data: any) => {
      forgotMutation.mutate(data);
    },
  });

  const forgotMutation = useMutation((email) => forgotPassword(email), {
    onSuccess: (res: any) => {
      setErrMsg('');
      formik.resetForm();
      TrackifyToast.success('We have emailed your password reset link!');
    },
    onError: (error: any) => {
      setErrMsg(error?.response?.data?.error?.message);
    },
  });

  return (
    <>
    <LoginHeaderLayout />
      <Container className="login">
        <Stack gap={4} className="col-md-12 mx-auto col-12 col-sm-12 col-lg-12 login-wrapper">
          <div className="p-4 login--inner">
            <h2>Forgot Password?</h2>
            <small>
              Please enter the email you use to sign in to your account.
            </small>
            <Form onSubmit={formik.handleSubmit} className="mt-3">
              {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.email && formik.errors.email
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  required
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>

              <Stack gap={2}>
                <Button variant="primary" type="submit">
                  Request Password Reset
                </Button>
                <Link className="mx-auto mt-3" to={routes.auth.login}>
                  Back to Login
                </Link>
              </Stack>
            </Form>
          </div>
        </Stack>
      </Container>
        <LoginFooterLayout />
    </>
  );
};

export default ForgotPassword;
