import AssignmentTypeResource from 'api/assignmentTypes';
import ProjectResource from 'api/projects';
import { default as TechStackResource } from 'api/techstacks';
import UserResource from 'api/user';
import CustomToast from 'components/toast/CustomToast';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import history from 'utils/history';
import { Member } from 'utils/interfaces/projectInterface';
const animatedComponents = makeAnimated();

interface ProjectMember {
  id: number | null;
  user_id: number | null;
  assignment_type_id: number | null;
  tech_stacks: number[];
}

const AssignMember: React.FC = () => {
  const { id }: any = useParams();
  const [selectedOption, setSelectedOption] = useState<any>();
  let projectAPI = new ProjectResource();
  let teamMemberAPI = new UserResource();
  let assignmentTypeAPI = new AssignmentTypeResource();
  let techStackAPI = new TechStackResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const [successData, setSuccessData] = useState<any>();
  const methods = useForm<any>();

  const [assignedMembers, setAssignedMembers] = useState<ProjectMember[]>([]);

  const projectQuery = useQuery(
    [`assign-project${id}`, id],
    async () => {
      const response = await projectAPI.get(id);
      if (response?.data?.data?.members.length > 0) {
        const members = await response?.data?.data?.members.map(
          (member: any) => {
            const membersResponse = response?.data?.data?.members.map(
              (m: Member) => ({
                id: m.id,
                user_id: m.user_id,
                assignment_type_id: m?.assignment_type
                  ? m.assignment_type.id
                  : null,
                tech_stacks:
                  m?.member_tech_stack.length > 0
                    ? m.member_tech_stack.map((t) => t.tech_stack_id)
                    : [],
              })
            );

            setAssignedMembers(membersResponse);

            return {
              label: member?.user?.name,
              value: member?.user_id,
            };
          }
        );
        if (window.location.pathname.includes('project'))
          setSelectedOption(members);
      } else {
        setAssignedMembers([]);
      }
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const assignProjectMember = useMutation(
    (data: any) => projectAPI.assignMember(id, data),
    {
      onSuccess: (res: any) => {
        setSuccessData({
          title: 'Assigned Member successfully.',
          status: 'success',
        });
        history.push({
          pathname: routes.projects.view.replace(':id', id),
        });
      },
      onError: (error: any, response: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const membersQuery = useQuery(
    'memebers',
    async () => {
      const response = await teamMemberAPI
        .list({ status: 'active', limit: 1000 })
        .then((res: any) => res.data.data);

      const members = response?.map((member: any) => ({
        label: member.name,
        value: member.id,
      }));

      return members;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const memberOptions = membersQuery?.data;

  const techStackQuery = useQuery(
    'techstacks',
    async () => {
      const response = await techStackAPI
        .list({ limit: 1000 })
        .then((res: any) => res?.data);

      const techStacks = response?.map((techStack: any) => ({
        label: techStack.name,
        value: techStack.id,
      }));

      return techStacks;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const techStackOptions = techStackQuery?.data;

  const assignmenyTypeQuery = useQuery(
    'assignment-types',
    async () => {
      const response = await assignmentTypeAPI
        .list({ limit: 1000 })
        .then((res: any) => res?.data);

      const assignmentType = response?.map((assignment: any) => ({
        label: assignment.name,
        value: assignment.id,
      }));

      return assignmentType;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const assignmentTypeOptions = assignmenyTypeQuery?.data;

  if (projectQuery.isError) {
    history.push(routes.projects.list);
  }

  const onSubmit = () => {
    assignProjectMember.mutate({ members: assignedMembers });
  };

  // handles changes
  const handleAddMember = () => {
    const newData: ProjectMember = {
      id: null,
      user_id: null,
      tech_stacks: [],
      assignment_type_id: null,
    };

    setAssignedMembers((prevState) => {
      const newState = [...prevState, newData];
      return newState;
    });
  };

  const handleTechStackChange = (
    index: number,
    value: MultiValue<{
      label: string;
      value: number;
    }>
  ) => {
    const techStackIds = value.map((v) => v.value);

    setAssignedMembers((prevState) => {
      const item = prevState[index];

      item.tech_stacks = techStackIds;

      assignedMembers[index] = item;

      return assignedMembers;
    });
  };

  const handleAssignmentChange = (
    index: number,
    value: SingleValue<{
      label: string;
      value: number | null;
    }>
  ) => {
    setAssignedMembers((prevState) => {
      const item = prevState[index];

      item.assignment_type_id = value?.value as number;

      assignedMembers[index] = item;

      return assignedMembers;
    });
  };

  //handles Deletion

  const handleDeleteMember = (index: number) => {
    const members = [...assignedMembers];

    members.splice(index, 1);

    setAssignedMembers(members);
  };

  return (
    <>
      <h2>Assign Member to Project: {projectQuery?.data?.name}</h2>
      <div className="col-md-12">
        {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
        {memberOptions &&
          techStackOptions &&
          assignmentTypeOptions &&
          assignedMembers.map((member: ProjectMember, index: number) => {
            //gives default selected values
            const defaultTech =
              techStackOptions &&
              techStackOptions?.filter((stack: any) => {
                return member.tech_stacks.includes(stack.value);
              });

            const defaultAssignment =
              assignmentTypeOptions &&
              assignmentTypeOptions?.filter((assignment: any) => {
                return assignment.value === member.assignment_type_id;
              });

            return (
              <div className="row d-flex mt-4" key={member.id}>
                <div className="col">
                  <Select
                    placeholder="Select Name"
                    options={memberOptions}
                    defaultValue={
                      memberOptions &&
                      memberOptions?.find(
                        (m: { label: string; value: number }) =>
                          m.value === member.user_id
                      )
                    }
                    getOptionLabel={(options: any) => options['label']}
                    getOptionValue={(options: any) => options['value']}
                    onChange={(e) => {
                      setAssignedMembers((prevState) => {
                        prevState[index].user_id = e.value;

                        return prevState;
                      });
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '25px',
                        borderColor: '#C6D2D9',
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: '-5px',
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col">
                  <Select
                    placeholder="Select TechStack"
                    options={techStackOptions}
                    onChange={(e) => handleTechStackChange(index, e)}
                    defaultValue={defaultTech}
                    isMulti
                    isClearable
                    getOptionLabel={(options: any) => options['label']}
                    getOptionValue={(options: any) => options['value']}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '25px',
                        borderColor: '#C6D2D9',
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: '-5px',
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col">
                  <Select
                    placeholder="Select Assignment Type"
                    options={assignmentTypeOptions}
                    defaultValue={defaultAssignment}
                    onChange={(e) => handleAssignmentChange(index, e)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '25px',
                        borderColor: '#C6D2D9',
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: '-5px',
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-danger "
                    onClick={() => handleDeleteMember(index)}>
                    <MdOutlineDeleteOutline />
                  </button>
                </div>
              </div>
            );
          })}

        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="primary"
            hidden={assignedMembers.length <= 0}
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            style={{ float: 'right' }}
            disabled={methods.formState.isSubmitting}>
            Submit
          </Button>
        </div>

        <div className="col">
          <button className="btn btn-primary mt-5" onClick={handleAddMember}>
            Add
          </button>
        </div>
      </div>
      {successData && <CustomToast toastData={successData} />}
    </>
  );
};

export default AssignMember;
