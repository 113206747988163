import clientResource from 'api/clients';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import HideControl from 'services/HideControl';
import TrackifyToast from 'utils/toast';

interface Props {
  clientData: any;
}

const ClientItem: React.FC<Props> = (props) => {
  const { clientData } = props;
  const clientAPI = new clientResource();
  const [show, setShow] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const queryClient = useQueryClient();

  const handleClose = () => {
    setDeleteErrorMsg('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const deleteClient = useMutation(
    (data: any) => clientAPI.update(clientData?.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listClient');
        handleClose();
        TrackifyToast.success('Client updated');
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onDeleteConfirm = () => {
    const status = clientData?.status === 'archived' ? 'active' : 'archived';
    deleteClient.mutate({ status: status });
  };

  return (
    <>
      <tr key={clientData.id}>
        <td
          style={
            clientData?.status == 'archived'
              ? { textDecoration: 'line-through' }
              : { textDecoration: 'none' }
          }>
          {clientData?.name}
        </td>
        <HideControl
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <td style={{ textTransform: 'capitalize' }}>{clientData?.status}</td>
          <td className="action-buttons">
            <RouterLink
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
              to={routes.clients.edit.replace(':id', clientData.id.toString())}>
              <BiEdit />
            </RouterLink>
          </td>
        </HideControl>
      </tr>
    </>
  );
};

export default ClientItem;
