import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

interface Props {
  teamGroupData?: any;
}

const TeamGroupForm: React.FC<Props> = (props) => {
  const { teamGroupData } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<any>();

  return (
    <>
      <form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="2">
            Group Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              defaultValue={teamGroupData?.name}
              className={errors?.name ? 'is-invalid' : ''}
              maxLength={24}
              placeholder="Group Name"
              {...register('name', {
                required: 'Group Name must be between 3 to 24 characters',
                minLength: {
                  value: 3,
                  message: 'Group Name must have atleast 3 characters',
                },
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name && errors?.name?.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </form>
    </>
  );
};

export default TeamGroupForm;
