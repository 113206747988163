import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

interface GroupChildren {
  name: string
  duration: string
}

interface Group {
  name: string
  children: GroupChildren[]
  total_duration:string
}

interface AccordComponentProps {
  group_one: Group[]
}

const AccordComponent: React.FC<AccordComponentProps>= ({group_one}) => {

  return (
    <div>
      {group_one?.map((items, idx) => {
        return (
          <Accordion defaultActiveKey="0"  key={idx}>
            <Accordion.Item eventKey="1" className='m-1'>
              <Accordion.Header >{items.name} - {items.total_duration}</Accordion.Header>
              {items?.children?.map((item, key) => {
                return (
                  <Accordion.Body key={key} style={{ borderBottom: "1px solid #ccc"}}>
                    <div className=" row px-4  ">
                      <div className="col col-sm-8 col-md-9 user">{item.name} </div>
                      <div className="col col-md-3 col-sm-4 duration" style={{ textAlign :'right'}}>{item.duration}</div>
                    </div> 
                  </Accordion.Body>
                );
              })}
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}

export default React.memo(AccordComponent);
