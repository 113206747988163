import { refreshUser } from 'api/auth';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'http-status-codes';
import * as authService from '../services/auth';
import moment from 'moment-timezone';
import http from './http';

const RETRY_COUNT_LIMIT = 3;
const AUTHORIZATION_HEADER = 'Authorization';
const SESSION_EXPIRE = 'This token is no longer available.';

/**
 * Build authorization header
 *
 * @param {string} accessToken
 * @returns {string}
 */
function buildAuthHeader(accessToken: string) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add authentication header for all requests.
 *
 * @param {object} request
 * @returns {object}
 */
export function requestInterceptor(request: any) {
  const accessToken = authService.getAccessToken();
  const timeZone = moment.tz.guess();

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = buildAuthHeader(accessToken);
  }

  request.headers.tz = timeZone;
  document.getElementById('spinner-container')?.classList.add('spinner-container');
  document.getElementById('spinner')?.classList.add('timer-loader');
  // document.body.classList.add('loading-indicator');
  return request;
}

/**
 * Success response Interceptor for refresh token.
 *
 * @param sucess
 * @returns {object}
 */
export async function responseSuccessInterceptor(response: AxiosResponse) {
  let originalRequest = response.config;

  if (
    originalRequest.url === '/users/refresh-token' &&
    response.status === 200
  ) {
    let accessToken = response.data.token;
    let refreshToken = response.data.refresh_token;
    let expiryTime = response.data.expires_in;

    authService.persist({ token: accessToken, refreshToken, expiryTime });
  }
  document.getElementById('spinner-container')?.classList.remove('spinner-container');
  document.getElementById('spinner')?.classList.remove('timer-loader');
  return response;
}

/**
 * Interceptor to refresh access token.
 *
 * @param {object} error
 * @returns {object}
 */
export async function responseErrorInterceptor(error: AxiosError) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config as any;
  // const id = originalRequest.url;

  if (originalRequest.url === '/auth/token' && error.response.status === 401) {
    // if refresh token is expired
    authService.logout();
  }

  const { code, message } = error.response.data?.error
    ? error.response.data.error
    : error.response.data;

  if (code === HttpStatus.UNAUTHORIZED && message === 'User Expired') {
    authService.logout();
  }

  if (
    code === HttpStatus.UNAUTHORIZED &&
    message === HttpStatus.getStatusText(HttpStatus.UNAUTHORIZED) &&
    !originalRequest.__isRetryRequest
  ) {
    originalRequest._retry = true;
    originalRequest.retryCount = isNaN(originalRequest.retryCount)
      ? 1
      : originalRequest.retryCount++;

    const refreshToken = authService.getRefreshToken();
    const { data } = await refreshUser(refreshToken || '');

    authService.setAccessToken(data.data.token);

    originalRequest.headers[AUTHORIZATION_HEADER] = buildAuthHeader(
      data.data.token
    );

    return http.request(originalRequest);
  }

  if (
    (code === HttpStatus.UNAUTHORIZED && message === SESSION_EXPIRE) ||
    originalRequest.retryCount > RETRY_COUNT_LIMIT
  ) {
    await authService.logout();
  }
  document.getElementById('spinner-container')?.classList.remove('spinner-container');
  document.getElementById('spinner')?.classList.remove('timer-loader');
  return Promise.reject(error);
}
